import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="working-pattern-form"
export default class extends Controller {
  static targets = ['existingWarningMessage']
  static values = {
    existingDefaultPattern: {
      type: Boolean,
      default: false
    }
  }

  checkExistingDefault ({ target }) {
    if (!this.existingDefaultPatternValue) return

    if (target.checked) {
      this.existingWarningMessageTarget.setAttribute('aria-hidden', false)
    } else {
      this.existingWarningMessageTarget.setAttribute('aria-hidden', true)
    }
  }
}
