import { Controller } from '@hotwired/stimulus'
import Trix from 'trix'

// Connects to data-controller="trix-config"
export default class extends Controller {
  connect () {
    this.element.addEventListener('trix-initialize', this.configureToolbar)
  }

  configureToolbar (event) {
    const lang = Trix.config.lang

    // The Trix toolbar is setup when the <trix-editor> element is defined, so it has to be overwritten.
    // The original can be found here:
    // https://github.com/basecamp/trix/blob/b6c0047f87fa078aa65beb8ba297aba32ea4bcf1/src/trix/config/toolbar.coffee
    event.target.toolbarElement.innerHTML = `
      <div class="trix-button-row">
        <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline" title="${lang.underline}" tabindex="-1">${lang.underline}</button>
        </span>
        <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
        </span>
        <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
        </span>
        <span class="trix-button-group-spacer"></span>
      </div>
      <div class="trix-dialogs" data-trix-dialogs>
        <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
          <div class="trix-dialog__link-fields">
            <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input disabled="disabled">
            <div class="trix-button-group">
              <input type="button" class="trix-button trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
              <input type="button" class="trix-button trix-button--dialog" value="${lang.unlink}" data-trix-method="removeAttribute">
            </div>
          </div>
        </div>
      </div>`
  }
}
