import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = ['startSelector', 'endSelector']

  connect () {
    const x = Array.from(this.endSelectorTargets)
    if (Array.isArray(x) && x.length !== 0) {
      this.startSelectorTargets.forEach(this.listenOnStartSelectorChange.bind(this))
    }
  }

  listenOnStartSelectorChange (startSelectorEl) {
    startSelectorEl.addEventListener('change', () => {
      Array.from(startSelectorEl.getElementsByTagName('input')).filter((startSelectorInputEl) => {
        return startSelectorInputEl.dataset.filtersEndValue !== undefined
      }).forEach((startSelectorInputEl) => this.updateEndSelectorInput(startSelectorEl, startSelectorInputEl))
    })
  }

  updateEndSelectorInput (startSelectorEl, startSelectorInputEl) {
    const endSelectorEl = this.endSelectorTargets.find((endSelectorEl) => {
      return endSelectorEl.id === startSelectorEl.dataset.filtersEndSelector
    })

    if (endSelectorEl !== undefined) {
      const endValueEl = Array.from(endSelectorEl.getElementsByTagName('input')).find((endSelectorInputEl) => {
        return endSelectorInputEl.value === startSelectorInputEl.dataset.filtersEndValue
      })

      if (endValueEl !== undefined) {
        endValueEl.checked = startSelectorInputEl.checked
      }
    }
  }
}
