import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="contracts--allowance-form"
export default class extends Controller {
  static targets = ['defaultValueField', 'valueField']

  connect () {}

  changeDefaultValue (event) {
    const allowances = JSON.parse(event.target.dataset.allowances)

    const allowanceID = event.target.value
    const allowance = allowances.find(
      element => element.id === allowanceID
    )

    if (allowance) {
      this.defaultValueFieldTarget.value = allowance.value

      if (allowance.apply_fte === true && allowance.contract_term_fte_factor) {
        const value = allowance.value * allowance.contract_term_fte_factor
        // Number.EPSILON ensures numbers like 1.005 are round correctly
        const roundedValue = Math.round((value + Number.EPSILON) * 100) / 100

        this.valueFieldTarget.value = roundedValue
      } else if (allowance.contact_term_fte_factor) {
        this.valueFieldTarget.value = allowance.value
      }
    }
  }
}
