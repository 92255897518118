import { Controller } from '@hotwired/stimulus'
import { VennDiagram } from '@upsetjs/venn.js'
import * as d3 from 'd3'
// Connects to data-controller="venn"
export default class extends Controller {
  static targets = ['diagram']
  static values = {
    chart: Array
  }

  connect () {
    const sets = this.chartValue

    if (sets.length > 0) {
      const chart = VennDiagram()
        .width(800)
        .height(500)

      const vennChart = d3.select('#venn').datum(sets)
      vennChart.call(chart)

      // enhance colour of the venn circle when hovering over it
      d3.selectAll('#venn .venn-circle')
        .on('mouseover', function (d, i) {
          const node = d3.select(this).transition()
          node.select('path').style('fill-opacity', 0.45)
        })
        .on('mouseout', function (d, i) {
          const node = d3.select(this).transition()
          node.select('path').style('fill-opacity', 0.25)
        })

      // enhance colour of the venn intersection when hovering over it
      d3.selectAll('#venn .venn-intersection')
        .on('mouseover', function (d, i) {
          const node = d3.select(this).transition()
          node.select('path').style('fill-opacity', 0.25)
        })
        .on('mouseout', function (d, i) {
          const node = d3.select(this).transition()
          node.select('path').style('fill-opacity', 0)
        })

      // allow entire section to be clicked rather than just text
      vennChart.selectAll('g.venn-area')
        .on('click', function (e, d) {
          document.getElementById(d.sets.join(('_'))).click()
        }).style('cursor', 'pointer')
    }
  }
}
