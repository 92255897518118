import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

// Connects to data-controller="demo-box-plot-chart"
export default class extends Controller {
  static values = {
    series: Array,
    labels: Array
  }

  connect () {
    const options = {
      theme: {
        palette: 'palette10'
      },
      chart: {
        type: 'boxPlot',
        height: 200
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      series: [{
        data: [{
          x: 'category 1',
          y: [40, 51.98, 56.29, 59.59, 63.85]
        }, {
          x: 'category 2',
          y: [43.66, 44.99, 51.35, 52.95, 59.42]
        }, {
          x: 'category n',
          y: [52.76, 57.35, 59.15, 63.03, 67.98]
        }]
      }]
    }
    this.chart = new ApexCharts(this.element, options)

    this.chart.render()
  }

  disconnect () {
    this.chart.destroy()
  }
}
