import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  static targets = ['toggleableField', 'dayCheckbox']

  toggleWorkDay (event) {
    event.preventDefault()

    event.target.checked ? this.addWorkDay(event.target.dataset.text, event.target.value) : this.removeWorkDay(event.target.dataset.text)
  }

  toggleWorkType () {
    const checkedWorkDays = this.dayCheckboxTargets.filter(checkbox => { return checkbox.checked })

    if (this.toggleableFieldTarget.value === 'flex') {
      checkedWorkDays.forEach((checkedWorkDay) => {
        this.addWorkDay(checkedWorkDay.dataset.text, checkedWorkDay.value)
      })
    } else {
      checkedWorkDays.forEach((checkedWorkDay) => {
        this.removeWorkDay(checkedWorkDay.dataset.text)
      })
    }
  }

  removeWorkDay (workDay) {
    const removeButton = document.querySelector(`button[data-for-work-day=${workDay}]`)
    if (!removeButton) return

    removeButton.click()
  }

  addWorkDay (workDay, weekDayIndex) {
    if (this.toggleableFieldTarget.value !== 'flex') return

    const content = this.templateTarget.innerHTML
      .replace(/NEW_RECORD/g, Math.random().toString().slice(2, 11))
      .replace(/NEW_CONTRACT_WORKING_PATTERN/g, Math.random().toString().slice(2, 11))
      .replace(/WORK_DAY_REPLACE/g, workDay)
      .replace(/WEEK_DAY_INDEX_REPLACE/g, weekDayIndex)

    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }
}
