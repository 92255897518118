import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="contracts--terms-form"
export default class extends Controller {
  static targets = [
    'hoursPerWeekField',
    'weeksPerYearField',
    'actualHoursPerWeekField',
    'actualWeeksPerYearField',
    'hourlyRateField',
    'salaryGradeSelect',
    'gradeScaleSelect',
    'fullTimeSalaryField',
    'gradeScaleSelectWarning',
    'fullTimeSalaryFieldNotice',
    'fullTimeSalaryFieldNoticeScalePoint',
    'fullTimeSalaryFieldNoticeScalePointMin',
    'fullTimeSalaryFieldNoticeScalePointMax',
    'fteFactorField',
    'salaryActualField'
  ]

  connect () {
    this.filterSalaryScalesOptions()
    this.changeHourlyRate()
  }

  changeHourlyRate () {
    const fullTimeSalaryValue = this.fullTimeSalaryFieldTarget.value

    if (fullTimeSalaryValue > 0) {
      const hourlyRateValue = fullTimeSalaryValue / 1265
      // Number.EPSILON ensures numbers like 1.005 are round correctly
      const roundedValue = Math.round((hourlyRateValue + Number.EPSILON) * 100) / 100

      this.hourlyRateFieldTarget.value = roundedValue
      this.hourlyRateFieldTarget.readOnly = true
    } else {
      this.hourlyRateFieldTarget.readOnly = false
    }
  }

  changeFullTimeHours (event) {
    const fullTimeHours = JSON.parse(event.target.dataset.wholeTimeEquivalentHours)

    const wholeTimeID = event.target.value
    const wholeTime = fullTimeHours.find(
      element => element.whole_time_id === wholeTimeID
    )

    if (wholeTime) {
      this.hoursPerWeekFieldTarget.value = wholeTime.hours_per_week
      this.weeksPerYearFieldTarget.value = wholeTime.weeks_per_year
      this.updateFteFactor()
    }
  }

  salaryScaleChange (event) {
    const gradeScaleSelect = event.target
    if (gradeScaleSelect.value !== '') {
      const selectedOption = gradeScaleSelect.options[gradeScaleSelect.selectedIndex]

      if (selectedOption.dataset.minValue) {
        if (selectedOption.dataset.maxValue) {
          this.fullTimeSalaryFieldTarget.readOnly = false
          this.fullTimeSalaryFieldTarget.value = ''
          this.fullTimeSalaryFieldTarget.min = selectedOption.dataset.minValue
          this.fullTimeSalaryFieldTarget.max = selectedOption.dataset.maxValue
          this.fullTimeSalaryFieldNoticeScalePointTarget.textContent = gradeScaleSelect.options[gradeScaleSelect.selectedIndex].text
          this.fullTimeSalaryFieldNoticeScalePointMinTarget.textContent = selectedOption.dataset.minValueFormatted
          this.fullTimeSalaryFieldNoticeScalePointMaxTarget.textContent = selectedOption.dataset.maxValueFormatted
          this.fullTimeSalaryFieldNoticeTarget.classList.remove('hidden')
        } else {
          this.fullTimeSalaryFieldTarget.readOnly = true
          this.fullTimeSalaryFieldTarget.value = selectedOption.dataset.minValue
          this.fullTimeSalaryFieldNoticeTarget.classList.add('hidden')
          this.gradeScaleSelectWarningTarget.classList.add('hidden')
        }
      }
    } else {
      this.fullTimeSalaryFieldTarget.readOnly = false
      this.fullTimeSalaryFieldTarget.value = ''
      this.fullTimeSalaryFieldNoticeTarget.classList.add('hidden')
    }
    this.updateFteFactor()
  }

  updateFteFactor () {
    const allValuesPresent = this.actualHoursPerWeekFieldTarget.value !== '' &&
      this.actualWeeksPerYearFieldTarget.value !== '' &&
      this.hoursPerWeekFieldTarget.value !== '' &&
      this.weeksPerYearFieldTarget.value !== ''

    if (allValuesPresent) {
      const fteValue = (this.actualHoursPerWeekFieldTarget.value * this.actualWeeksPerYearFieldTarget.value) /
        (this.hoursPerWeekFieldTarget.value * this.weeksPerYearFieldTarget.value)

      this.fteFactorFieldTarget.value = Math.round((fteValue + Number.EPSILON) * 100) / 100
    } else {
      this.fteFactorFieldTarget.value = ''
    }

    this.updateSalaryActual()
  }

  updateSalaryActual () {
    if (this.fteFactorFieldTarget.value !== '' && this.fullTimeSalaryFieldTarget.value !== '') {
      this.salaryActualFieldTarget.value = this.fteFactorFieldTarget.value * this.fullTimeSalaryFieldTarget.value
    } else {
      this.salaryActualFieldTarget.value = ''
    }
  }

  filterSalaryScalesOptions (salaryGradeId = this.salaryGradeSelectTarget.value) {
    Array.prototype.forEach.call(this.gradeScaleSelectTarget.options, option => {
      if (option.value === '') { return }

      if (option.dataset.salaryGradeId !== salaryGradeId) {
        option.style.display = 'none'
      } else {
        option.style.display = 'block'
      }
    })
  }

  salaryGradeChange (event) {
    const salaryGradeId = event.target.value
    this.gradeScaleSelectTarget.value = ''
    this.fullTimeSalaryFieldTarget.readOnly = false
    this.fullTimeSalaryFieldNoticeTarget.classList.add('hidden')

    if (salaryGradeId) {
      this.gradeScaleSelectTarget.disabled = false
      this.filterSalaryScalesOptions(salaryGradeId)

      if (this.fullTimeSalaryFieldTarget.value !== '') {
        this.gradeScaleSelectWarningTarget.classList.remove('hidden')
      } else {
        this.gradeScaleSelectWarningTarget.classList.add('hidden')
      }
    } else {
      this.gradeScaleSelectTarget.disabled = true
      this.gradeScaleSelectWarningTarget.classList.add('hidden')
    }
  }

  fullTimeSalaryChange (event) {
    this.updateSalaryActual()

    if (event.target.value !== '' && this.salaryGradeSelectTarget.value !== '') {
      this.gradeScaleSelectWarningTarget.classList.remove('hidden')
    } else {
      this.gradeScaleSelectWarningTarget.classList.add('hidden')
    }
  }
}
