import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="actions-menu-item-button"
export default class extends Controller {
  static targets = ['actionsMenuItem']

  connect () {}

  change (event) {
    this.actionsMenuItemTarget.disabled = true
  }
}
