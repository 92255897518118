import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="password-visibility"
export default class extends Controller {
  static targets = ['passwordField']

  toggle (event) {
    const { target: { checked } } = event
    this.passwordFieldTarget.type = checked ? 'text' : 'password'
  }

  toggleFromIcon () {
    if (this.passwordFieldTarget.type === 'password') {
      this.passwordFieldTarget.type = 'text'
    } else {
      this.passwordFieldTarget.type = 'password'
    }
  }
}
