import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="other-absence-form"
export default class extends Controller {
  static targets = ['absenceSettingSelect', 'paidRadioButton', 'unpaidRadioButton']

  connect () {
    this.absenceSettingSelectTarget.addEventListener('change', (event) => {
      const selectedOptionPaymentState = event.target.options[event.target.selectedIndex].dataset.paymentState

      if (selectedOptionPaymentState === 'paid') {
        this.paidRadioButtonTarget.checked = true
      } else if (selectedOptionPaymentState === 'unpaid') {
        this.unpaidRadioButtonTarget.checked = true
      }
    })
  }
}
