import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="contracts--pension-form"
export default class extends Controller {
  static targets = ['mcrEmploymentTypeField']

  connect () { }

  pensionSchemeChange (e) {
    const select = e.target
    const selectedOptionText = select.options[select.selectedIndex].text

    if (selectedOptionText === 'TPS Pension') {
      this.mcrEmploymentTypeFieldTarget.classList.remove('hidden')
    } else {
      this.mcrEmploymentTypeFieldTarget.classList.add('hidden')
      this.mcrEmploymentTypeFieldTarget.value = ''
    }
  }
}
