import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

// Connects to data-controller="chart"
export default class extends Controller {
  static values = {
    options: String,
    dataLabelFormatter: String,
    xaxisLabelFormatter: String,
    yaxisLabelFormatter: String,
    datasetWeightings: String
  }

  connect () {
    const options = this.options

    const dataLabelFormatter = this.dataLabelFormatter
    const xaxisLabelFormatter = this.xaxisLabelFormatter
    const yaxisLabelFormatter = this.yaxisLabelFormatter
    const datasetWeightings = this.datasetWeightings

    function tooltipTitle (title) {
      return '<div class="apexcharts-tooltip-title">' +
        title +
      '</div>'
    }

    function yValueDiv (name, value) {
      return '<div class="apexcharts-tooltip-y-group">' +
        '<span class="apexcharts-tooltip-text-y-label">' +
          name + ':' +
        '</span>' +
        '<span class="apexcharts-tooltip-text-y-value">' +
          value +
        '</span>' +
      '</div>'
    }

    function goalsDiv (goals) {
      let goalsHtml = ''
      goals.forEach(function (goal) {
        goalsHtml += '<span class="apexcharts-tooltip-text-goals-label">' +
        '<div>' +
          '<div style="display: flex">' +
            '<span class="apexcharts-tooltip-marker" style="background-color: ' +
              goal.strokeColor +
              '; height: 3px; border-radius: 0; top: 5px;">' +
            '</span>' +
            goal.name +
          '</div>' +
        '</div>' +
      '</span>' +
      '<span class="apexcharts-tooltip-text-goals-value">' +
        '<div>' +
          '<div>' +
          options.tooltip.y.formatter(
            goal.value
          ) +
          '</div>' +
        '</div>' +
      '</span>'
      })

      return '<div class="apexcharts-tooltip-goals-group">' +
        goalsHtml +
      '</div>'
    }

    function weightingDiv (organisationName) {
      if (datasetWeightings.body.find(
        weighing => weighing.organisation_name === organisationName
      ) != null) {
        return '<div class="weightings apexcharts-tooltip-y-group">' +
          '<span class="apexcharts-tooltip-text-y-label">' +
            'Weighting: ' +
          '</span>' +
          '<span class="apexcharts-tooltip-text-y-value">' +
            datasetWeightings.body.find(
              weighing => weighing.organisation_name === organisationName
            ).weighting +
          '</span>' +
        '</div>'
      } else {
        return ''
      }
    }

    if (dataLabelFormatter) {
      // eslint-disable-next-line no-new-func
      options.dataLabels.formatter = Function('value', dataLabelFormatter.body)
      // eslint-disable-next-line no-new-func
      options.tooltip.y.formatter = Function('value', dataLabelFormatter.body)
      if (datasetWeightings != null && datasetWeightings.body.length > 0) {
        options.tooltip.custom = function ({ series, seriesIndex, dataPointIndex, w }) {
          return tooltipTitle(w.globals.labels[dataPointIndex]) +
            '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">' +
              '<span class="apexcharts-tooltip-marker">' +
              '</span>' +
              '<div class="apexcharts-tooltip-text">' +
                yValueDiv(
                  w.globals.seriesNames[0],
                  options.tooltip.y.formatter(series[seriesIndex][dataPointIndex])
                ) +
                goalsDiv(w.globals.seriesGoals[seriesIndex][dataPointIndex]) +
                weightingDiv(w.globals.labels[dataPointIndex]) +
              '</div>' +
              '</div>'
        }
      }
    }
    if (xaxisLabelFormatter) {
      // eslint-disable-next-line no-new-func
      options.xaxis.labels.formatter = Function('value', xaxisLabelFormatter.body)
    }
    if (yaxisLabelFormatter) {
      // eslint-disable-next-line no-new-func
      options.yaxis.labels.formatter = Function('value', yaxisLabelFormatter.body)
    }

    this.chart = new ApexCharts(this.element, options)

    this.chart.render()

    window.dispatchEvent(new Event('resize'))
  }

  get options () {
    if (this.optionsValue) {
      return JSON.parse(this.optionsValue)
    } else {
      return {
        chart: {
          type: 'bar',
          height: 350
        },
        noData: {
          text: 'No data available for selected options',
          align: 'center',
          verticalAlign: 'middle',
          style: {
            fontSize: '14px'
          }
        },
        series: []
      }
    }
  }

  get xaxisLabelFormatter () {
    return this.parseFormatter(this.xaxisLabelFormatterValue)
  }

  get yaxisLabelFormatter () {
    return this.parseFormatter(this.yaxisLabelFormatterValue)
  }

  get dataLabelFormatter () {
    return this.parseFormatter(this.dataLabelFormatterValue)
  }

  get datasetWeightings () {
    return this.parseFormatter(this.datasetWeightingsValue)
  }

  parseFormatter (formatter) {
    if (formatter) {
      return JSON.parse(formatter)
    }
  }

  disconnect () {
    this.chart.destroy()
  }
}
