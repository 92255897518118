import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'
import Dropzone from 'dropzone'

Dropzone.autoDiscover = false

function getMetaValue (name) {
  const element = findElement(document.head, `meta[name="${name}"]`)
  if (element) {
    return element.getAttribute('content')
  }
}

function findElement (root, selector) {
  return document.querySelector(selector)
}

function removeElement (el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el)
  }
}

function insertAfter (el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling)
}

// Connects to data-controller="dropzone"
export default class extends Controller {
  static targets = ['input']

  connect () {
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
    this.dropZone.options.dictRemoveFile = "<i class='fa fa-close'></i>"
    this.dropZone.options.dictCancelUpload = "<i class='fa fa-close'></i>"
  }

  remove (element) {
    element.target.closest('.dz-preview').remove()
  }

  // Private
  hideFileInput () {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = 'none'
  }

  bindEvents () {
    this.dropZone.on('addedfile', (file) => {
      setTimeout(() => { file.accepted && createDirectUploadController(this, file).start() }, 500)
    })

    this.dropZone.on('removedfile', (file) => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on('canceled', (file) => {
      file.controller && file.controller.xhr.abort()
    })

    this.dropZone.on('processing', (file) => {
      this.submitButton.disabled = true
    })

    this.dropZone.on('queuecomplete', (file) => {
      this.submitButton.disabled = false
    })
  }

  get headers () { return { 'X-CSRF-Token': getMetaValue('csrf-token') } }

  get url () { return this.inputTarget.getAttribute('data-direct-upload-url') }

  get maxFiles () { return this.data.get('maxFiles') || 1 }

  get maxFileSize () { return this.data.get('maxFileSize') || 256 }

  get acceptedFiles () { return this.data.get('acceptedFiles') }

  get addRemoveLinks () { return this.data.get('addRemoveLinks') || true }

  get form () { return this.element.closest('form') }

  get submitButton () { return findElement(this.form, 'input[type=submit], button[type=submit]') }
}

class DirectUploadController {
  constructor (source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start () {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  // Private
  createHiddenInput () {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR (xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent (xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener('progress', event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress (event) {
    findElement(this.file.previewTemplate, '.dz-preview').style.cssText = 'opacity: 1; transform: translateY(0)'
    findElement(this.file.previewTemplate, '.dz-image').remove()
    findElement(this.file.previewTemplate, '.dz-success-mark').remove()
    findElement(this.file.previewTemplate, '.dz-error-mark').remove()
    findElement(this.file.previewTemplate, '.dz-remove').innerHTML = '<i class="fa-solid fa-xmark"></i>'
  }

  emitDropzoneUploading () {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit('processing', this.file)
  }

  emitDropzoneError (error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit('error', this.file, error)
    this.source.dropZone.emit('complete', this.file)
  }

  emitDropzoneSuccess () {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit('success', this.file)
    this.source.dropZone.emit('complete', this.file)
  }
}

// Top level...
function createDirectUploadController (source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload (file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone (controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.element.getAttribute('data-dropzone-max-files') || controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.element.getAttribute('data-dropzone-file-types') || controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    uploadMultiple: true
  })
}
