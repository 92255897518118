// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'
import './trix_config'
import '@rails/actiontext'
import LocalTime from 'local-time'
import * as ActiveStorage from '@rails/activestorage'

LocalTime.start()
ActiveStorage.start()

window.Turbo.setConfirmMethod((message, element) => {
  const dialogId = element.dataset.turboConfirmDialogId || null
  const customDialog = document.getElementById(dialogId)

  if (customDialog) {
    customDialog.showModal()

    return new Promise((resolve) => {
      customDialog.addEventListener('close', () => {
        resolve(customDialog.returnValue === 'confirm')
      }, { once: true })
    })
  } else {
    return window.confirm(message)
  }
})

// Stop Hotwire's idiotic frame:missing behaviour
// https://github.com/hotwired/turbo/pull/863
document.addEventListener('turbo:frame-missing', function (event) {
  if (event.detail.response && event.detail.response.status >= 500) {
    // Visit the response
    event.preventDefault()
    event.detail.visit(event.detail.response)
  } else {
    console.log('Frame missing: ' + event.detail.url)
  }
})

// Extend elements with data-extend-to-window-height to the bottom of
// the page, also when window is resized
function extendElementsToWindowHeight () {
  const extendables = document.querySelectorAll('[data-extend-to-window-height]')

  extendables.forEach((element) => {
    element.style.height = `calc(100vh - ${element.getBoundingClientRect().top}px)`
  })
}

window.onresize = extendElementsToWindowHeight
extendElementsToWindowHeight()
