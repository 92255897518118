import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="contracts--details-form"
export default class extends Controller {
  static targets = ['postField', 'roleField']

  connect () {}

  changePostAndRole (event) {
    const jobRoleTraits = JSON.parse(event.target.dataset.jobRoleTraitIds)

    const jobRoleID = event.target.value
    const jobRole = jobRoleTraits.find(
      element => element.job_role_id === jobRoleID
    )

    if (jobRole) {
      const postSelectIndex = Array.from(this.postFieldTarget.options).findIndex(
        element => element.value === jobRole.post_trait_id
      )

      const roleSelectIndex = Array.from(this.roleFieldTarget.options).findIndex(
        element => element.value === jobRole.role_trait_id
      )

      this.postFieldTarget.options.selectedIndex = postSelectIndex
      this.roleFieldTarget.options.selectedIndex = roleSelectIndex
    }
  }
}
