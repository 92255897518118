import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

// Connects to data-controller="demo-bar-chart-stacked"
export default class extends Controller {
  connect () {
    const options = {
      theme: {
        palette: 'palette10'
      },
      series: [{
        name: 'Marine Sprite',
        data: [22, 43, 21]
      }, {
        name: 'Striking Calf',
        data: [13, 43, 32]
      }, {
        name: 'Tank Picture',
        data: [15, 11, 20]
      }, {
        name: 'Bucket Slope',
        data: [6, 9, 4]
      }, {
        name: 'Reborn Kid',
        data: [25, 24, 10]
      }],
      chart: {
        type: 'bar',
        stacked: true,
        height: 200
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: [2012, 2013, 2014],
        labels: {
          formatter: function (val) {
            return val + 'K'
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + 'K'
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
    }
    this.chart = new ApexCharts(this.element, options)

    this.chart.render()
  }

  disconnect () {
    this.chart.destroy()
  }
}
