import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="time-input"
export default class extends Controller {
  static targets = ['hour', 'minute']
  inputs = []

  connect () {
    this.inputs = [this.hourTarget, this.minuteTarget]
    this.inputs.forEach((input) => {
      input.addEventListener('input', () => {
        this.handleInput(input)
      })
    })
  }

  disconnect () {
    this.inputs.forEach((input) => {
      input.removeEventListener('input', () => {
        this.handleInput(input)
      })
    })
  }

  handleInput (input) {
    if (input.value.length === Number(input.getAttribute('maxlength')) && this.inputs.find((inp) => inp.value === '')) {
      this.inputs.find((inp) => inp.value === '').focus()
    }
  }
}
