import { Controller } from '@hotwired/stimulus'

// Connects to data-controller='form-toggle'
export default class extends Controller {
  static targets = ['toggleableField']

  connect () {}

  changed (event) {
    const siblingToggleableField = event.currentTarget.parentNode.children.item(
      event.currentTarget.parentNode.children.length - 1
    )
    this.toggle(siblingToggleableField)
  }

  // private

  toggle (element) {
    if (element.classList.contains('toggleable-hidden')) {
      element.classList.remove('toggleable-hidden')
    } else {
      element.classList.add('toggleable-hidden')
    }
  }
}
