import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'

// Connects to data-controller="tooltip"
export default class extends Controller {
  static targets = ['container', 'template']
  static values = {
    position: String
  }

  tooltip = undefined

  #showEvents = ['mouseenter', 'focus']
  #hideEvents = ['mouseleave', 'blur']

  get #tooltipElement () {
    return this.hasContainerTarget ? this.containerTarget : this.element
  }

  get #tooltipPosition () {
    return this.hasPositionValue ? this.positionValue : 'top'
  }

  connect () {
    const target = this.#tooltipElement
    const template = this.templateTarget

    const pos = this.#tooltipPosition

    this.tooltip = createPopper(target, template, {
      placement: pos,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'right']
          }
        }
      ]
    })

    this.#connectEventListeners()
  }

  disconnect () {
    this.#disconnectEventListeners()
    super.disconnect()
  }

  show () {
    this.templateTarget.setAttribute('data-show', '')
    this.tooltip.update()
  }

  hide () {
    this.templateTarget.removeAttribute('data-show')
  }

  #connectEventListeners () {
    this.#showEvents.forEach((event) => {
      this.#tooltipElement.addEventListener(event, this.show.bind(this))
    })

    this.#hideEvents.forEach((event) => {
      this.#tooltipElement.addEventListener(event, this.hide.bind(this))
    })
  }

  #disconnectEventListeners () {
    this.#showEvents.forEach((event) => {
      this.#tooltipElement.removeEventListener(event, this.show.bind(this))
    })

    this.#hideEvents.forEach((event) => {
      this.#tooltipElement.removeEventListener(event, this.hide.bind(this))
    })
  }
}
