import { LimitedSelectionCheckboxesController } from 'stimulus-library'
export default class CheckboxLimitedSelectionController extends LimitedSelectionCheckboxesController {
  static values = { max: Number, min: Number, message: String }

  _handleInputs (event) {
    this.messageMaxValue = 'Not allowed to select more than '.concat(this.maxValue, ' items')
    this.messageMinValue = 'Not allowed to select less than '.concat(this.minValue, ' items')

    const tickedInputs = this.inputTargets.reduce((previousValue, el) => el.checked ? previousValue + 1 : previousValue, 0)
    const target = event.target

    let maxCheck = false
    let minCheck = false

    if (this.minValue === this.maxValue) {
      minCheck = true
    } else {
      if (tickedInputs < this.minValue) {
        event.preventDefault()
        target.checked = true
        this.dispatchEvent(target, 'change')
        this.dispatchEvent(target, this.eventName('too-few'))
        if (this.hasErrorTarget && this.hasMessageValue) {
          this.errorTarget.innerHTML = this.minValue
        }
      } else {
        minCheck = true
      }
    }

    if (tickedInputs > this.maxValue) {
      event.preventDefault()
      target.checked = false
      this.dispatchEvent(target, 'change')
      this.dispatchEvent(target, this.eventName('too-many'))
      if (this.hasErrorTarget && this.hasMessageValue) {
        this.errorTarget.innerHTML = this.messageMaxValue
      }
    } else {
      maxCheck = true
    }

    if (maxCheck && minCheck) {
      this.dispatchEvent(target, this.eventName('selection'))
      event.preventDefault()
      if (this.hasErrorTarget) {
        this.errorTarget.innerHTML = ''
      }
    }
  }
}
