import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="checkbox-nested-list"
export default class extends Controller {
  static values = {
    exclusive: Boolean
  }

  connect () {
    this.initTree()
  }

  initTree () {
    const exclusiveMode = this.exclusiveValue

    function checkBoxNestList (list) {
      const children = Array.from(list.children).filter(function (item) {
        return item.matches('.checkbox-nest-option')
      })
      children.forEach(function (child) {
        const label = child.querySelector('label')
        const icon = child.querySelector('.checkbox-nest-icon') ? child.querySelector('.checkbox-nest-icon') : null
        const innerList = child.querySelector('ul') ? child.querySelector('ul') : null
        const parent = !child.parentNode.classList.contains('checkbox-nest-list') ? child.parentNode : null

        // set indeterminates on page load/reload
        const input = label.querySelector('input')
        if (parent && parent.querySelectorAll('li input').length > 0) {
          if (parent.querySelectorAll('li input:checked').length === 0) {
            closeAllIndeterminates(parent.parentNode)
          } else if (parent.querySelectorAll('li input').length !== parent.querySelectorAll('li input:checked').length) {
            openIndeterminates(parent.parentNode)
          } else {
            checkInputs(parent.parentNode)
          }
        }

        if (icon) {
          icon.addEventListener('click', function () {
            child.classList.toggle('open')
            icon.classList.toggle('active')
          })
        }
        label.addEventListener('change', function () {
          if (innerList) {
            // Un/Check all nested items under parent
            const innerListitems = innerList.querySelectorAll('li')
            if (input.checked === true) {
              innerListitems.forEach(function (item) {
                item.querySelector('input').checked = true
                item.querySelector('input').indeterminate = false
                item.classList.add('active')
              })
            } else {
              innerListitems.forEach(function (item) {
                item.querySelector('input').checked = false
                item.querySelector('input').indeterminate = false
                item.classList.remove('active')
              })
            }
          }
          if (parent && parent.querySelectorAll('li input').length > 0) {
            if (parent.querySelectorAll('li input:checked').length === 0) {
              closeAllIndeterminates(parent.parentNode)
            } else if (parent.querySelectorAll('li input').length !== parent.querySelectorAll('li input:checked').length) {
              openIndeterminates(parent.parentNode)
            } else {
              checkInputs(parent.parentNode)
            }
          }

          // Exclusive mode unchecks all other nested lists when an input is checked
          if (this.querySelector('input').checked && exclusiveMode === true) {
            // We've checked a nested item inside the list
            if (parent !== null) {
              uncheckListsExcept(parent.parentNode)
            }
            // We've checked the list parent itself
            if (parent === null) {
              uncheckListsExcept(this.parentNode.parentNode)
            }
          }
        })

        if (innerList) {
          // Open current list if we have selected innerList elements
          if (innerList.querySelectorAll('li input:checked').length > 0) {
            child.classList.toggle('open')
            icon.classList.toggle('active')
          }

          // Setup nested checkbox list
          checkBoxNestList(innerList)
        }
      })
    }

    function closeAllIndeterminates (parent) {
      parent.querySelector('input').checked = false
      parent.querySelector('input').indeterminate = false
      parent.querySelectorAll('ul li input').forEach(function (item) {
        if (parent.querySelectorAll('ul li input:checked').length > 0) {
          parent.querySelector('input').indeterminate = true
        } else {
          item.checked = false
          parent.querySelector('input').indeterminate = false
        }
      })
      if (parent.parentNode.parentNode.classList.contains('checkbox-nest-option-list')) {
        closeAllIndeterminates(parent.parentNode.parentNode)
      }
    }

    function openIndeterminates (parent) {
      parent.querySelector('input').indeterminate = true
      if (parent.parentNode.parentNode.classList.contains('checkbox-nest-option-list')) {
        if (parent.querySelectorAll('ul li input:checked')) {
          openIndeterminates(parent.parentNode.parentNode)
        }
      }
    }

    function checkInputs (parent) {
      parent.querySelector('input').indeterminate = false
      parent.querySelector('input').checked = true
    }

    function uncheckListsExcept (listItem) {
      // Find all lists from the parent of the parent
      const allLists = listItem.parentNode.querySelectorAll('* > .checkbox-nest-option-list')
      // Find all lists that don't contain this freshly-checked item
      const otherLists = Array.from(allLists).filter(function (item) {
        return item !== listItem
      })
      // Uncheck everything in the other lists and clear any intermediates
      if (otherLists.length > 0) {
        otherLists.forEach(function (list) {
          const checkedListItems = list.querySelectorAll('* > li input:checked')
          checkedListItems.forEach(function (input) {
            input.checked = false
          })
          closeAllIndeterminates(list)
        })
      }
    }

    const nestLists = document.querySelectorAll('.checkbox-nest-list')
    nestLists.forEach(function (nestList) {
      checkBoxNestList(nestList)
    })
  }
}
