import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="summary-details-table"
export default class extends Controller {
  static targets = ['expandRows', 'collapseRows', 'detailsRow']

  connect () {
    this.collapseRowsTargets.forEach(collapseControl => {
      this.connectEventListener(collapseControl, 'none')
    })
    this.expandRowsTargets.forEach(expandControl => {
      this.connectEventListener(expandControl, '')
    })
  }

  connectEventListener (button, displayOption) {
    button.addEventListener(
      'click',
      function (evt) {
        evt.preventDefault()
        this.expandCollapse(button, displayOption)
      }.bind(this)
    )
  }

  expandCollapse (button, displayOption) {
    const summaryRowControls = button.closest('.summary-row-controls')
    const summaryId = summaryRowControls.dataset.summary

    this.toggleRows(summaryId, displayOption)
    this.toggleControls(summaryRowControls)
  }

  toggleRows (summaryId, displayOption) {
    const rowsToToggle = this.detailsRowTargets.filter((row) => {
      return row.dataset.summary === summaryId
    })

    if (!rowsToToggle) {
      return
    }

    for (const row of rowsToToggle) {
      row.style.display = displayOption
    }
  }

  toggleControls (summaryRowControls) {
    for (const element of summaryRowControls.children) {
      if (element.classList.contains('toggleable-hidden')) {
        element.classList.remove('toggleable-hidden')
      } else {
        element.classList.add('toggleable-hidden')
      }
    }
  }
}
