import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'

// Connects to data-controller="form-validator"
export default class extends Controller {
  static targets = ['validationSubmit']

  initialize () {
    this.validate = debounce(this.validate, 200).bind(this)
    this.debounceTrixEditor()
  }

  connect () {
    this.validationSubmitTarget.style.visibility = 'hidden'
  }

  validate () {
    this.validationSubmitTarget.click()
  }

  private

  debounceTrixEditor () {
    const trixFormFields = document.getElementsByTagName('trix-editor')
    if (!trixFormFields) { return }

    for (const field of trixFormFields) {
      field.addEventListener('focusout', debounce(this.validate.bind(this), 200))
    }
  }
}
