import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

// Connects to data-controller="demo-minimal-bar-chart"
export default class extends Controller {
  connect () {
    const options = {
      theme: {
        palette: 'palette10'
      },
      series: [{
        data: [4080, 4350]
      }],
      chart: {
        type: 'bar',
        height: 150
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['Boys', 'Girls']
      }
    }
    this.chart = new ApexCharts(this.element, options)

    this.chart.render()
  }

  disconnect () {
    this.chart.destroy()
  }
}
