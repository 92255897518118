import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']

  connect () {
    setTimeout(() => {
      this.show()
    }, 0)
  }

  show () {
    if (!this.element.classList.contains('active')) this.element.classList.add('active')
  }

  hide () {
    this.element.classList.remove('active')
  }

  submitAndClose () {
    if (this.buttonTarget.form) {
      const newSubmitEvent = new Event('submit', { bubbles: true, cancelable: true })
      this.buttonTarget.form.dispatchEvent(newSubmitEvent)
    }

    this.close()
  }

  close (event = null) {
    if (event?.target?.hidden || this.element.contains(event?.target)) {
      return
    }
    this.hide()
    setTimeout(() => {
      this.element.remove()
    }, 500)
  }

  remove () {
    this.element.remove()
  }
}
