import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="observation-attributes"
export default class extends Controller {
  static outlets = ['modal', 'searchable-item-picker']
  static targets = ['listFrame']
  static values = {
    itemParam: String
  }

  connect () {
    this.element.addEventListener('removeItem', this.removeItem.bind(this))
  }

  showModalPicker (event) {
    event.preventDefault()
    this.modalOutlet.show(event)
    this.searchableItemPickerOutlet.refreshContent()
    this.element.addEventListener('dismiss', this.updateItems.bind(this))
    this.element.addEventListener('loadItems', this.updateItems.bind(this))
  }

  updateItems (event) {
    this.modalOutlet.hide(event)
    const itemsUrl = new URL(this.listFrameTarget.src)
    itemsUrl.search = this.itemsQueryString()
    this.listFrameTarget.src = itemsUrl.href
  }

  removeItem (event) {
    const itemId = event.target.dataset.itemId
    const itemCheckbox = this.modalOutlet.element.querySelector(`input[value="${itemId}"]`)
    itemCheckbox.checked = false
    this.updateItems(event)
    this.searchableItemPickerOutlet.refreshContent()
  }

  itemsQueryString () {
    const itemIdParam = `&${this.itemParamValue}[]=`
    const itemIds = `${itemIdParam}${this.selectedItemIds().join(itemIdParam)}`
    return `${itemIds}&removable=true&show_popover=${this.showPopoverParam()}`
  }

  selectedItemIds () {
    const selectedOptions = this.modalOutlet.element.querySelectorAll('input[type=checkbox]:checked')
    return Array.from(selectedOptions).map(option => option.value)
  }

  showPopoverParam () {
    const checkbox = this.element.querySelector('.popover input[type=checkbox]')
    if (checkbox !== null && checkbox.checked) {
      return true
    } else {
      return false
    }
  }
}
