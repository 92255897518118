import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="attachments"
export default class extends Controller {
  static targets = ['deletedAttachments']
  static values = {
    confirm: String
  }

  markAttachmentForDeletion (event) {
    event.preventDefault()
    if (window.confirm(this.confirmValue)) {
      this.removeAttachmentFromDOM(event)
      this.addAttachmentHiddenFieldToDOM(event)
    }
  }

  addAttachmentHiddenFieldToDOM (event) {
    const attachmentId = event.target.parentElement.dataset.attachmentid
    this.deletedAttachmentsTarget.innerHTML += this.hiddenFieldForDeletedAttachment(attachmentId)
  }

  removeAttachmentFromDOM (event) {
    const attachmentElement = event.target.closest('.observations__attachment')
    attachmentElement.remove()
  }

  hiddenFieldForDeletedAttachment (attachmentId) {
    return `<input multiple='multiple' value=${attachmentId} type="hidden" name="deleted_attachments[]" id="deleted_attachments"></input>`
  }
}
