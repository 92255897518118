import {
  Controller
} from '@hotwired/stimulus'

// Connects to data-controller="assessments--grade-picker"
export default class extends Controller {
  static targets = ['assessmentHeader']

  static values = {
    gradesets: Array,
    bandlabels: Object
  }

  connect () {
    this.currentSelectedCell = null
    // Bind a click event to every td with the grade-picker class
    document.querySelectorAll('.grade-picker').forEach((el) => {
      this.addClickEvent(el)
    })
  }

  addClickEvent (element) {
    // check if the clickHandler event listener has already been added
    if (element.dataset.gradePicker === 'true') {
      return
    }
    element.dataset.gradePicker = 'true'
    element.addEventListener('click', this.clickHandler.bind(this))
  }

  removeClickEvent (element) {
    // check if the clickHandler event listener has already been added
    if (element.dataset.gradePicker !== 'true') {
      return
    }

    element.dataset.gradePicker = 'false'
    element.removeEventListener('click', this.clickHandler)
  }

  clickHandler (e) {
    const thisCell = e.target.closest('.grade-picker')

    // remove the click handler from the current cell
    this.removeClickEvent(thisCell)

    // Remove any existing grade picker
    this.closeMenu()

    // Remove the "focussed" class from the previously selected cell
    if (this.currentSelectedCell) {
      this.currentSelectedCell.classList.remove('focussed')
    }

    // Add the "focussed" class to the selected cell
    thisCell.classList.add('focussed')
    this.currentSelectedCell = thisCell

    // Use the data in the cell to get the gradeset
    const gradeSetId = this.currentSelectedCell.dataset.gradeSet

    // if the gradeSet is not set then return
    if (!gradeSetId) {
      console.error('No gradeSetId found (1)')
      return
    }

    // get the gradeSet from the gradeSets array by id
    const gradeSet = this.gradesetsValue.find((gs) => gs.id === gradeSetId)
    if (!gradeSet) {
      console.error('No gradeSet found (2)')
      return
    }

    // get the current year for the student
    const ncYear = this.currentSelectedCell.dataset.ncYear
    const studentFullName = this.currentSelectedCell.dataset.studentFullName
    const studentAgeInMonths = parseInt(this.currentSelectedCell.dataset.studentAgeInMonths)
    const input = this.currentSelectedCell.querySelector('input[name="assessment_result[grade_id]"]')
    const form = this.currentSelectedCell.querySelector('form')
    const viewportOffset = this.currentSelectedCell.getBoundingClientRect()
    const pixelsLeftInScreen = (window.innerHeight - viewportOffset.top)

    const top = 59 // Set to bottom of the grid row
    // const page = document.getElementById('page-content');

    // If dropdown will be visible don't scroll
    const gradePickerHTML = this.renderGradePickerComponent(gradeSet, ncYear, studentFullName, studentAgeInMonths, form, input, top)
    this.currentSelectedCell.appendChild(gradePickerHTML)

    this.currentSelectedCell.querySelector('.search-input input').focus()

    if (pixelsLeftInScreen < 250) {
      gradePickerHTML.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    }

    // const initialScroll = page.scrollTop;
    // // console.log(initialScroll);

    // page.addEventListener("scroll", (event) => {
    //   console.log(`${top - page.scrollTop}px`);
    //   gradePickerHTML.style.top = `${top - page.scrollTop}px`;
    //   console.log(page.scrollTop);
    // });
  }

  closeMenu () {
    if (this.currentSelectedCell) {
      // add the click handler back to the current cell
      this.addClickEvent(this.currentSelectedCell)

      // clear the gradePickerHTMLTemplate from current selected cell
      const gradeSelectorTemplate = this.currentSelectedCell.querySelector('.grade-picker__grade-selector')

      if (gradeSelectorTemplate !== null) {
        gradeSelectorTemplate.remove()
      }
    }
  }

  scrollIntoViewIfNeeded (target) {
    if (target.getBoundingClientRect().bottom > window.innerHeight) {
      target.scrollIntoView(false)
    }

    if (target.getBoundingClientRect().top < 0) {
      target.scrollIntoView()
    }
  }

  renderGradePickerComponent (gradeSet, ncYear, studentFullName, studentAgeInMonths, form, input, top) {
    if (window.location.href.includes('/age/')) {
      return this.renderAgesPicker(gradeSet, ncYear, studentFullName, studentAgeInMonths, form, input, top)
    }
    const bands = new Set(gradeSet.grades.map((grade) => grade.band))
    if (bands.size === 1) {
      return this.renderSimpleMenu(gradeSet, ncYear, studentFullName, form, input, top)
    } else {
      return this.renderComplexMenu(gradeSet, ncYear, studentFullName, form, input, top)
    }
  }

  renderAgesPicker (gradeSet, ncYear, studentFullName, studentAgeInMonths, form, input, top) {
    const data = gradeSet.grades.reduce((acc, grade) => {
      const year = Math.floor(grade.value / 12)
      const month = grade.value % 12
      acc[year] = acc[year] || []
      acc[year][month] = acc[year][month] || []
      acc[year][month] = grade
      return acc
    }, {})

    const yearOptions = Object.entries(data).map((kv) => {
      if (Math.floor(studentAgeInMonths / 12) === parseInt(kv[0])) {
        return `<option value='${kv[0]}'>${kv[0]} *</option>`
      } else {
        return `<option value='${kv[0]}'>${kv[0]}</option>`
      }
    })

    const monthOptions = (forYear) => {
      if (forYear && Array.isArray(data[forYear])) {
        return Object.entries(data[forYear]).map((kv) => {
          if ((Math.floor(studentAgeInMonths / 12) === parseInt(forYear)) && (studentAgeInMonths % 12 === parseInt(kv[0]))) {
            return `<option value='${kv[1].id}'>${kv[0]} *</option>`
          } else {
            return `<option value='${kv[1].id}'>${kv[0]}</option>`
          }
        })
      }
      return []
    }

    const agePickerMenu = document.createElement('div')
    agePickerMenu.classList.add('grade-picker__grade-selector')
    agePickerMenu.style = `top: ${top}px;`
    agePickerMenu.innerHTML = `
    <div class="grade-picker__header">
        <h4>${studentFullName}</h4>
        <div class="func_close grade-picker__close-button fa fa-remove"></div>
    </div>
    <div class="form-grouped-fields">
      <div>
        <label>Years</label>
        <select id="grade-picker__year-select">${yearOptions.join('')}</select>
      </div>
      <div>
        <label>Months</label>
        <select id="grade-picker__month-select"></select>
      </div>
    </div>
    <div class="grade-picker__footer">
      <button id="grade-picker__cancel" class="button" data-type="cancel">
        <span>Cancel</span>
      </button>
      <button id="grade-picker__remove" class="button" data-type="danger">
        <span>Remove</span>
      </button>
      <button id="grade-picker__ok" class="button" data-type="success">
        <span>OK</span>
      </button>
    </div>
  `
    const selectGrade = (id) => {
      let found = false
      for (let i = 0; i < gradeSet.grades.length; i++) {
        if (gradeSet.grades[i].id === id) {
          found = true
          const year = Math.floor(gradeSet.grades[i].value / 12)
          agePickerMenu.querySelector('#grade-picker__year-select').value = year
          agePickerMenu.querySelector('#grade-picker__month-select').innerHTML = monthOptions(year).join('')
          agePickerMenu.querySelector('#grade-picker__month-select').value = gradeSet.grades[i].id
        }
      }
      if (found === false) {
        agePickerMenu.querySelector('#grade-picker__year-select').selectedIndex = 0
        agePickerMenu.querySelector('#grade-picker__month-select').innerHTML = monthOptions(agePickerMenu.querySelector('#grade-picker__year-select').value).join('')
        agePickerMenu.querySelector('#grade-picker__month-select').selectedIndex = 0
      }
    }

    agePickerMenu.addEventListener('click', (e) => {
      e.stopPropagation()
    })

    agePickerMenu.querySelector('#grade-picker__year-select').addEventListener('change', (e) => {
      agePickerMenu.querySelector('#grade-picker__month-select').innerHTML = monthOptions(e.target.value).join('')
      agePickerMenu.querySelector('#grade-picker__month-select').selectedIndex = 0
    })

    agePickerMenu.querySelector('#grade-picker__ok').addEventListener('click', (e) => {
      e.stopPropagation()
      input.value = agePickerMenu.querySelector('#grade-picker__month-select').value
      this.closeMenu()
      form.requestSubmit()
    })

    agePickerMenu.querySelector('#grade-picker__remove').addEventListener('click', (e) => {
      e.stopPropagation()
      input.value = ''
      this.closeMenu()
      form.requestSubmit()
    })

    agePickerMenu.querySelector('#grade-picker__cancel').addEventListener('click', (e) => {
      e.stopPropagation()
      this.closeMenu()
    })

    if (input.value) {
      selectGrade(input.value)
    } else {
      for (let j = 0; j < gradeSet.grades.length; j++) {
        if (parseInt(gradeSet.grades[j].value) === studentAgeInMonths) {
          selectGrade(gradeSet.grades[j].id)
        }
      }
    }

    this.addCloseButton(agePickerMenu)
    return agePickerMenu
  }

  renderSimpleMenu (gradeSet, ncYear, studentFullName, form, input, top) {
    const areCodes = this.getAgeRelatedExpectationCodes(gradeSet, ncYear)
    const gradesList = gradeSet.grades
      .sort((a, b) => a.position - b.position)
      .map((grade) => `
            <li class="grade-picker__grade-option" data-grade-id="${grade.id}" data-grade-band="${grade.band}" data-search="${grade.description}">
              <span class="indicator-container">
                <span class="indicator__circle" ${this.indicatorCircleCategory(gradeSet, grade, areCodes)}"></span>
                <span>${grade.name}</span>
              </span>
            </li>`
      )

    const blankGrade = `<li class="grade-picker__grade-option" data-grade-id="" data-grade-band="" data-search="">
        <span class="indicator-container">
          <span class="fa fa-times"></span>
          <span>Remove grade</span>
        </span>
      </li>`

    const filterGrades = (event) => {
      const search = gradePickerMenu.querySelector('#grade-picker__grade-search').value.toString()
      gradePickerMenu.querySelectorAll('.grade-picker__grade-option').forEach((el) => {
        if (typeof search === 'string' && search.length > 0) {
          if (!el.dataset.search.toLowerCase().includes(search.toLowerCase())) {
            el.style.display = 'none'
          } else {
            el.style.display = 'block'
          }
        } else {
          el.style.display = 'block'
        }
      })

      const filteredGradeOptions = Array.from(gradePickerMenu.querySelectorAll('.grade-picker__grade-option')).filter((el) => {
        return window.getComputedStyle(el).display === 'block'
      })

      const gradeOption = filteredGradeOptions[0]

      filteredGradeOptions.length === 1 ? gradeOption.classList.add('highlight') : gradeOption.classList.remove('highlight')

      if (filteredGradeOptions.length === 1) {
        if (event.key === 'Enter') {
          event.stopPropagation()
          input.value = gradeOption.dataset.gradeId
          this.closeMenu()
          form.requestSubmit()
        }
      }
    }

    const gradePickerMenu = document.createElement('div')
    gradePickerMenu.classList.add('grade-picker__grade-selector')
    gradePickerMenu.style = `top: ${top}px;`
    gradePickerMenu.innerHTML = `
          <div class="grade-picker__header">
            <h4>${studentFullName}</h4>
            <i class="grade-picker__close-button fa fa-remove"></i>
          </div>
          <div class="search-input">
            <i class="fa-solid fa-magnifying-glass icon"></i>
            <input id="grade-picker__grade-search" placeholder="Search Grades" type="text">
          </div>
          <div class="panel">
            <div class="panel-body">
              <ul id="grade-picker__grade-select">
                ${blankGrade}
                ${gradesList.join('')}
              </ul>
            </div>
      `

    // Bind the click event to the grades
    gradePickerMenu.querySelectorAll('.grade-picker__grade-option').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.stopPropagation()
        const gradeOption = e.currentTarget
        input.value = gradeOption.dataset.gradeId
        this.closeMenu()
        form.requestSubmit()
      })
    })

    gradePickerMenu.querySelector('#grade-picker__grade-search').addEventListener('keyup', filterGrades)

    gradePickerMenu.addEventListener('click', (e) => {
      e.stopPropagation()
    })

    this.addCloseButton(gradePickerMenu)

    return gradePickerMenu
  }

  renderComplexMenu (gradeSet, ncYear, studentFullName, form, input, top) {
    const areCodes = this.getAgeRelatedExpectationCodes(gradeSet, ncYear)
    const bandOptions = Object.entries(this.bandlabelsValue)
      .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
      .map((kv) => {
        return `<option value="${kv[0]}">${kv[1]}</option>`
      })

    const gradesOrdered = gradeSet.grades.sort((a, b) => {
      if (!gradeSet.has_bands || a.band === b.band) {
        return a.position - b.position
      } else {
        return a.band - b.band
      }
    })

    const gradesList = gradesOrdered.map(grade => {
      return `<li class="grade-picker__grade-option" data-grade-id="${grade.id}" data-grade-band="${grade.band}" data-search="${grade.description}">
              <span class="indicator-container">
                <span class="indicator__circle" ${this.indicatorCircleCategory(gradeSet, grade, areCodes)}"></span>
                <span>${grade.name}</span>
                </span>
            </li>`
    })

    const blankGrade = `<li class="grade-picker__grade-option" data-grade-id="" data-grade-band="" data-search="">
        <span class="indicator-container">
          <span class="fa fa-times"></span>
          <span>Remove grade</span>
        </span>
      </li>`

    const gradePickerMenu = document.createElement('div')
    gradePickerMenu.classList.add('grade-picker__grade-selector')
    gradePickerMenu.style = `top: ${top}px;`
    gradePickerMenu.innerHTML = `

    <div class="grade-picker__header">
        <h4>${studentFullName !== undefined ? studentFullName : 'Student'}</h4>
        <div class="grade-picker__close-button fa fa-remove"></div>
    </div>

    <select id="grade-picker__band-select">
        ${bandOptions.join('')}
    </select>

    <div class="search-input">
        <i class="fa-solid fa-magnifying-glass icon"></i>
        <input id="grade-picker__grade-search" placeholder="Search Grades" type="text">
    </div>

    <div class="panel">
        <div class="panel-body">
            <ul id="grade-picker__grade-select">
                ${blankGrade}
                ${gradesList.join('')}
            </ul>
        </div>
    </div>
    `
    const selectBand = (id) => {
      for (let i = 0; i < gradeSet.grades.length; i++) {
        if (gradeSet.grades[i].id === id) {
          gradePickerMenu.querySelector('#grade-picker__band-select').value = gradeSet.grades[i].band
        }
      }
    }

    const filterGrades = (event) => {
      const search = gradePickerMenu.querySelector('#grade-picker__grade-search').value.toString()
      const band = gradePickerMenu.querySelector('#grade-picker__band-select').value.toString()
      const gradeOptions = gradePickerMenu.querySelectorAll('#grade-picker__grade-select li')
      let filteredGradeOptions = []

      gradeOptions.forEach((el) => {
        if (el.dataset.gradeBand.toString() === band || el.dataset.gradeBand.toString() === '') {
          el.style.display = 'block'
          if (typeof search === 'string' && search.length > 0) {
            if (!el.dataset.search.toLowerCase().includes(search.toLowerCase())) {
              el.style.display = 'none'
            }
          }
        } else {
          el.style.display = 'none'
        }
      })

      setTimeout(() => {
        filteredGradeOptions = Array.from(gradePickerMenu.querySelectorAll('.grade-picker__grade-option')).filter((el) => {
          return window.getComputedStyle(el).display === 'block'
        })
        if (filteredGradeOptions.length === 1) {
          filteredGradeOptions[0].classList.add('highlight')

          if (event.key === 'Enter') {
            const gradeOption = filteredGradeOptions[0]
            event.stopPropagation()
            input.value = gradeOption.dataset.gradeId
            this.closeMenu()
            form.requestSubmit()
          }
        }
      }, 0)
    }

    // Bind the click event to the grades
    gradePickerMenu.querySelector('#grade-picker__band-select').addEventListener('change', filterGrades)
    gradePickerMenu.querySelector('#grade-picker__grade-search').addEventListener('keyup', filterGrades)

    gradePickerMenu.addEventListener('click', (e) => {
      e.stopPropagation()
    })

    gradePickerMenu.querySelectorAll('.grade-picker__grade-option').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.stopPropagation()
        const gradeOption = e.currentTarget
        input.value = gradeOption.dataset.gradeId
        this.closeMenu()
        form.requestSubmit()
      })
    })

    if (input.value) {
      selectBand(input.value)
    } else {
      gradePickerMenu.querySelector('#grade-picker__band-select').value = ncYear
    }

    filterGrades()
    this.addCloseButton(gradePickerMenu)

    return gradePickerMenu
  }

  getAgeRelatedExpectationCodes (gradeSet, ncYear) {
    ncYear = parseInt(ncYear)
    const areMin = 1
    const areMax = 5
    const areMid = 3
    const areCodes = {}
    let defaultExpectationUpperBound = null
    let defaultExpectationLowerBound = null

    let significanceThreshold = null
    if (gradeSet.nc_year_significance_thresholds) {
      const significanceThresholds = gradeSet.nc_year_significance_thresholds.filter((nyst) => nyst.nc_year === ncYear)

      if (significanceThresholds.length > 0) {
        significanceThreshold = significanceThresholds[0].significance_threshold
      }
    }

    for (let i = 0; i < gradeSet.grades.length; i++) {
      const band = gradeSet.has_bands ? parseInt(gradeSet.grades[i].band) : ncYear
      const position = parseInt(gradeSet.grades[i].position)
      if (band < ncYear) {
        areCodes[gradeSet.grades[i].id] = areMin
      }
      if (band > ncYear) {
        areCodes[gradeSet.grades[i].id] = areMax
      }
      if (band === ncYear) {
        areCodes[gradeSet.grades[i].id] = areMid
        if (gradeSet.grades[i].default_expectation === true) {
          if (defaultExpectationLowerBound === null || defaultExpectationLowerBound > position) {
            defaultExpectationLowerBound = position
          }
          if (defaultExpectationUpperBound === null || defaultExpectationUpperBound < position) {
            defaultExpectationUpperBound = position
          }
        }
      }
    }

    for (let j = 0; j < gradeSet.grades.length; j++) {
      const band = gradeSet.has_bands ? parseInt(gradeSet.grades[j].band) : ncYear
      const position = parseInt(gradeSet.grades[j].position)
      if (band === ncYear) {
        let areCategory = 0
        if (defaultExpectationLowerBound && defaultExpectationUpperBound) {
          if (position < defaultExpectationLowerBound) {
            // If the grade is below the default expectation, and the difference between the grade and the default expectation is greater than the significance threshold, then it is a category 1 grade
            if (significanceThreshold && (defaultExpectationLowerBound - position) > significanceThreshold) {
              areCategory = 1
            } else {
              areCategory = 2
            }
          } else if (position === defaultExpectationUpperBound) {
            areCategory = 3
          } else if (position > defaultExpectationUpperBound) {
            // If the grade is above the default expectation, and the difference between the grade and the default expectation is greater than the significance threshold, then it is a category 5 grade
            if (significanceThreshold && (position - defaultExpectationUpperBound) > significanceThreshold) {
              areCategory = 5
            } else {
              areCategory = 4
            }
          }
        }
        if (gradeSet.grade_set_kind === 'formative' && gradeSet.grades[j].value === 0) {
          areCategory = 0
        }
        areCodes[gradeSet.grades[j].id] = areCategory
      }
    }
    return areCodes
  }

  addCloseButton (gradePickerMenu) {
    gradePickerMenu.querySelectorAll('.grade-picker__close-button').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.stopImmediatePropagation()
        this.closeMenu()
      })
    })
  }

  indicatorCircleCategory (gradeSet, grade, areCodes) {
    if (gradeSet.grade_set_kind === 'formative') {
      return `data-formative-category="formative-${grade.position}`
    } else {
      return `data-are-category="are-${areCodes[grade.id]}`
    }
  }
}
