import NestedForm from 'stimulus-rails-nested-form'

// Connects to data-controller="subgrade-form"
export default class extends NestedForm {
  static targets = [
    'gradeScale',
    'removeSubgradeButton'
  ]

  add (event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_GRADE/g, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove (event) {
    super.remove(event)
    const visibleGradeScales = this.gradeScaleTargets.filter(elment => elment.style.display !== 'none')
    if (visibleGradeScales.length === 0) {
      this.removeSubgradeButtonTarget.click()
    }
  }
}
