import { Controller } from '@hotwired/stimulus'

// Connects to data-controller='form-checkbox'
export default class extends Controller {
  static targets = ['toggleableField']

  onChanged () {
    this.toggleableFieldTarget.toggleAttribute('readOnly')
  }
}
