import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

// Connects to data-controller="demo-pie-chart"
export default class extends Controller {
  static values = {
    series: Array,
    labels: Array
  }

  connect () {
    const options = {
      chart: {
        type: 'donut'
      },
      theme: {
        palette: 'palette10'
      },
      fill: {
        type: 'solid'
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'bottom'
      },
      plotOptions: {
        pie: {
          donut: {
            size: '60%'
          }
        }
      },
      customScale: 0.6,
      tooltip: {
        fillSeriesColor: false
      },
      series: this.seriesValue,
      labels: this.labelsValue
    }

    this.chart = new ApexCharts(this.element, options)

    this.chart.render()
  }

  disconnect () {
    this.chart.destroy()
  }
}
