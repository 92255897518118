import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="copy-clipboard"
export default class extends Controller {
  handleCopy (event) {
    let copyHTML
    const copyContent = this.element.querySelectorAll('.jui-copy')
    copyContent.forEach((item) => {
      copyHTML += item.innerHTML
    })
    const input = document.createElement('input')
    input.type = 'text'
    input.value = copyHTML.trim()
    document.body.appendChild(input)
    input.select()
    document.execCommand('Copy')
    document.body.removeChild(input)
    event.target.parentNode.querySelector('.tooltip-text').innerHTML = 'Copied <i class="fa-solid fa-check"></i>'

    setTimeout(() => {
      event.target.parentNode.querySelector('.tooltip-text').innerHTML = 'Copy'
    }, 2000)
  }
}
