import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'
import { DEBOUNCETIME } from '../../utils/constants'
import { post } from '@rails/request.js'

// Connects to data-controller="soft-validations--email-address"
export default class extends Controller {
  static targets = ['message']
  static values = { url: String }

  connect () {
    this.check = debounce(this.check, DEBOUNCETIME).bind(this)
  }

  async check (event) {
    const { target: { value: inputEmailAddress } } = event

    if (!inputEmailAddress || !this.hasUrlValue) {
      return
    }

    //   Find out if the email address matches any others in the system
    const request = post(this.urlValue, {
      body: JSON.stringify({ validations: { contact_email_address: inputEmailAddress } }),
      responseKind: 'turbo-stream'
    })
    await request
  }
}
