import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="form-required-field"
export default class extends Controller {
  static targets = ['requiredFieldLabel', 'requiredField', 'dependentField']
  static values = { requiredIf: String }

  updateRequiredField () {
    const required = this.dependentFieldTarget.value === this.requiredIfValue
    this.requiredFieldTarget.required = required
    this.requiredFieldLabelTarget.classList.toggle('required', required)
  }
}
