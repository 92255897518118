import { Controller } from '@hotwired/stimulus'
import CSVBoxImporter from '@csvbox/csvboxjs'

export default class extends Controller {
  static values = {
    licenseKey: String,
    userId: String,
    organisationId: String
  }

  connect () {
    if (this.licenseKeyValue === '') {
      console.warn('No License Key found for CSVBox. Disabling CSV import.')
      return
    }

    this.element.addEventListener('click', () => this.openModal())
  }

  openModal () {
    this.importer = new CSVBoxImporter(this.licenseKeyValue, {}, this.callback.bind(this), { lazy: true })
    this.importer.setOptions({ dynamic_list_request_headers: { 'ngrok-skip-browser-warning': '69420' } })
    this.importer.setUser({ user_id: this.userIdValue, organisation_id: this.organisationIdValue })
    this.importer.openModal()
  }

  callback (result, data) {
    //
  }
}
