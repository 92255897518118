import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

// Connects to data-controller="select"
export default class extends Controller {
  connect () {
    this.initTomSelect()
  }

  disconnect () {
    if (this.select) {
      this.select.destroy()
    }
  }

  initTomSelect () {
    if (this.element) {
      const options = JSON.parse(this.element.dataset.options || '[]')
      const items = JSON.parse(this.element.dataset.selectedItems || '[]')
      const currentOptions = options.map(option => option.value)
      const optionsToAdd = []

      items.forEach(item => {
        if (currentOptions.includes(item) === false) {
          optionsToAdd.push({ value: item, text: item })
        }
      })

      options.push(...optionsToAdd)

      this.select = new TomSelect(this.element, {
        plugins: ['remove_button'],
        selectOnTab: true,
        closeAfterSelect: true,
        hidePlaceholder: true,
        create: true,
        openOnFocus: true,
        highlight: true,
        options,
        items
      })
    }
  }
}
