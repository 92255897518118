import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['expandedState', 'closePill']
  static values = {
    frameTag: {
      type: String,
      default: 'results'
    }
  }

  connect () {
    this.onFrameLoaded()
  }

  updatePillsHref () {
    this.#updateParameters('.in-table-filter')
    this.#updateParameters('.pagy-nav a')
  }

  #updateParameters (selector) {
    document.querySelectorAll(selector).forEach(matchedElement => {
      let url = matchedElement.href ? matchedElement.href : matchedElement.action
      let filteredParams = []
      const [origin, params] = url.split('?')

      if (params !== undefined) { filteredParams = params.split('&').filter(encodedParam => !encodedParam.includes('expanded_field')) }

      const enCodedName = encodeURIComponent('state[expanded_field]')
      filteredParams.push(`${enCodedName}=${this.expandedStateTarget.value}`)

      url = [origin, filteredParams.join('&')].join('?').replace(window.location.origin, '')

      if (matchedElement.href) matchedElement.href = url
      else matchedElement.action = url
    })
  }

  removePill ({ target }) {
    target.closest('.filter-label').remove()
    this.element.requestSubmit()
  }

  onFrameLoaded () {
    const aElement = document.getElementById('export-csv')
    if (!aElement) return

    const [currentOrigin] = aElement.href.split('?')
    const turboFrame = document.getElementById(this.frameTagValue)

    if (!turboFrame || !turboFrame.src) return

    const turboParams = turboFrame.src.split('?')[1]
    aElement.href = [currentOrigin.replace(window.location.origin, ''), turboParams].join('?')
  }
}
