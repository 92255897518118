import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="button-toggle"
export default class extends Controller {
  connect () {
    this.disable()
  }

  changed (event) {
    (event.currentTarget.value === 'true') ? this.enable() : this.disable()
  }

  enable () {
    this.element.elements.namedItem('submit-button').disabled = false
  }

  disable () {
    this.element.elements.namedItem('submit-button').disabled = true
  }
}
