import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.carousels = this.element.querySelectorAll('.carousel')
    this.initializeCarousel()
  }

  initializeCarousel () {
    if (this.carousels.length === 0) {
      this.completeOnboarding()
    }

    this.carousels.forEach((carousel) => {
      const carouselNext = carousel.querySelector('.carousel-btn-next')
      const carouselGrid = carousel.querySelector('.carousel-grid')
      const carouselImgs = carousel.querySelectorAll('.carousel-img')
      const carouselBodies = carousel.querySelectorAll('.carousel-body')
      const carouselDots = carousel.querySelectorAll('.carousel-dot')

      let carouselStep = Number(carouselGrid.getAttribute('data-modal-step'))

      carouselNext.addEventListener('click', (event) => {
        carouselStep += 1
        if (carouselStep >= carouselBodies.length - 1) {
          carouselStep = carouselBodies.length - 1
          carouselNext.textContent = 'Finish'

          carouselNext.addEventListener(
            'click',
            this.completeOnboarding.bind(this)
          )
        } else {
          carouselNext.textContent = 'Next'

          carouselNext.removeEventListener('click', this.completeOnboarding)
        }

        this.carouselStepNav(carouselDots, carouselStep)
        this.carouselStepNav(carouselBodies, carouselStep)
        this.carouselStepNav(carouselImgs, carouselStep)
      })
    })
  }

  carouselStepNav (arr, count) {
    arr.forEach(function (item, index) {
      if (index === count) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }
    })
  }

  completeOnboarding () {
    this.element.style.display = 'none'

    const csrfToken = (document.querySelector('[name="csrf-token"]') || {})
      .content
    const userIdInput = this.element.querySelector('input[name="user_id"]')
    const onboardingPageIdInput = this.element.querySelector(
      'input[name="onboarding_page_id"]'
    )

    const userId = userIdInput.value
    const onboardingPageId = onboardingPageIdInput.value

    fetch('/user_onboarding_completion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        user_id: userId,
        onboarding_page_id: onboardingPageId
      })
    })
  }
}
