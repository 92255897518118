import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="disable-button"
export default class extends Controller {
  static targets = ['button']

  connect () {}

  change (event) {
    setTimeout(() => {
      this.buttonTarget.disabled = true
    }, 0)

    this.resetButton()
  }

  private

  resetButton () {
    setTimeout(() => {
      this.buttonTarget.disabled = false
    }, 3000)
  }
}
