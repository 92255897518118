import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="field-disable-toggle"
export default class extends Controller {
  static targets = ['control', 'disableField', 'fieldsContainer']

  connect () {
    this.controlTargets.forEach((control) => {
      control.addEventListener('click', (event) => {
        this.resetRadioButtons()
        control.checked = true
        this.resetDisabledFields()
        this.disableFields(control)
      })
    })

    // Default to the first segment if nothing else is selected
    if (!this.controlTargets.map((control) => control.checked).includes(true)) {
      this.controlTargets[0].click()
    } else {
      this.controlTargets.find((control) => control.checked === true).click()
    }
  }

  resetRadioButtons () {
    this.controlTargets.forEach((control) => { control.checked = false })
  }

  resetDisabledFields () {
    this.disableFieldTargets.forEach((field) => { field.disabled = false })
  }

  disableFields (control) {
    const disabledFieldIds = control.dataset.disabledFieldIds

    if (disabledFieldIds) {
      JSON.parse(disabledFieldIds).forEach((id) => {
        this.fieldsContainerTarget.querySelector('#' + id).disabled = true
      })
    }
  }
}
