import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="nested-form-select-toggle"
export default class extends Controller {
  static targets = ['triggerField', 'toggleableSection']

  connect () {
    if (this.triggerFieldTarget.type === 'radio') {
      this.toggleSectionByRadioButton()
    } else {
      this.toggleSectionBySelect()
    }
  }

  changed () {
    if (this.triggerFieldTarget.type === 'radio') {
      this.toggleSectionByRadioButton()
    } else {
      this.toggleSectionBySelect()
    }
  }

  // private

  toggleSectionBySelect () {
    const selectOption = this.triggerFieldTarget.options[this.triggerFieldTarget.options.selectedIndex].text
    const toggleOptions = this.triggerFieldTarget.dataset.toggleOptions

    if (toggleOptions.includes(selectOption)) {
      this.addNestedForm()
      this.toggleableSectionTarget.style.display = 'initial'
    } else if (!toggleOptions.includes(selectOption)) {
      this.removeNestedForm()
      this.toggleableSectionTarget.style.display = 'none'
    }
  }

  toggleSectionByRadioButton () {
    const radioButton = this.triggerFieldTarget

    if (radioButton.checked) {
      this.addNestedForm()
      this.toggleableSectionTarget.style.display = 'initial'
    } else {
      this.removeNestedForm()
      this.toggleableSectionTarget.style.display = 'none'
    }
  }

  addNestedForm () {
    if (this.toggleableSectionTarget.getElementsByClassName('nested-form-wrapper').length === 0) {
      this.toggleableSectionTarget.querySelector('a[data-type="add"]').click()
    }
  }

  removeNestedForm () {
    const nestedForms = this.toggleableSectionTarget.getElementsByClassName('nested-form-wrapper')

    if (this.toggleableSectionTarget.style.display === 'initial' && nestedForms.length > 0) {
      Array.from(nestedForms).forEach(function (form) {
        form.querySelector('a.remove-form').click()
      })
    }
  }
}
