import { Controller } from '@hotwired/stimulus'

// Connects to data-controller='form-hide-banners'
export default class extends Controller {
  submit () {
    const banners = document.querySelectorAll('.alert-banner')

    if (banners.length > 0) {
      banners.forEach(element => element.remove())
    }
  }
}
