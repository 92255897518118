import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

// Connects to data-controller="demo-bar-chart"
export default class extends Controller {
  connect () {
    const options = {
      theme: {
        palette: 'palette10'
      },
      chart: {
        type: 'bar',
        height: 200
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      series: [{
        data: [{
          x: 'Whole MAT',
          y: 10
        }, {
          x: 'School A',
          y: 18
        }, {
          x: 'School B',
          y: 13
        }]
      }]
    }
    this.chart = new ApexCharts(this.element, options)

    this.chart.render()
  }

  disconnect () {
    this.chart.destroy()
  }
}
