import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

// Connects to data-controller="sortable-list"

export default class extends Controller {
  static values = { element: String }

  connect () {
    const list = document.getElementById(this.elementValue)
    this.sortable = Sortable.create(list, { filter: '.disable-sorting' })
  }
}
