import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="disciplinary-form"
export default class extends Controller {
  static targets = [
    'investigationOutcome',
    'hearingDetails'
  ]

  connect () {
    this.showHideHearingDetails()
  }

  showHideHearingDetails () {
    const hearingInputs = Array.from(this.hearingDetailsTarget.getElementsByTagName('input')).map(input => {
      if (input.classList.contains('trix-button')) {
        return ''
      } else {
        return input.value
      }
    })

    if (hearingInputs.join('').length === 0) {
      this.hearingDetailsTarget.hidden = ['no_case_to_answer', ''].includes(this.investigationOutcomeTarget.value)
    } else {
      this.hearingDetailsTarget.hidden = false
    }
  }
}
