import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.element.addEventListener('click', (event) => {
      if (event.target.closest('.progress-accordion__intro')) {
        this.toggleAccordion()
      }
    })

    this.refreshAccordion()
    this.updateAccordionStepCount()
    this.updateProgressBar()
  }

  updateAccordionStepCount () {
    const checkboxes = this.element.querySelectorAll('.checkbox')
    this.accordionStepCount = 0

    checkboxes.forEach((checkbox) => {
      if (checkbox.classList.contains('checked')) {
        this.accordionStepCount++
      }
    })
  }

  toggleAccordion () {
    if (this.contentElement.style.maxHeight) {
      this.closeAccordion()
    } else {
      this.openAccordion()
    }
  }

  openAccordion () {
    this.element.classList.add('active')
    this.contentElement.style.maxHeight = this.contentElement.scrollHeight + 'px'
  }

  closeAccordion () {
    this.element.classList.remove('active')
    this.contentElement.style.maxHeight = null
  }

  updateProgressBar () {
    this.barElements.forEach((bar, index) => {
      if (index <= this.accordionStepCount - 1) {
        if (!bar.classList.contains('active')) {
          bar.classList.add('active')
        }
      } else {
        bar.classList.remove('active')
      }
    })
  }

  refreshAccordion () {
    this.closeAccordion()
  }

  get contentElement () {
    return this.element.querySelector('.progress-accordion__content')
  }

  get barElements () {
    return this.element.querySelectorAll('.progress-accordion__bar')
  }
}
