import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="omnibar"
export default class extends Controller {
  static targets = ['button', 'menu']
  toggled

  connect () {
    this.toggled = false
  }

  toggle () {
    this.toggled ? this.hide() : this.show()
  }

  show () {
    this.toggled = true
    this.buttonTarget.setAttribute('aria-expanded', 'true')
    this.menuTarget.setAttribute('aria-open', 'true')
  }

  hide () {
    this.toggled = false
    this.buttonTarget.setAttribute('aria-expanded', 'false')
    this.menuTarget.setAttribute('aria-open', 'false')
  }
}
