import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

// Connects to data-controller="demo-pie-chart"
export default class extends Controller {
  static values = {
    series: Array,
    labels: Array
  }

  connect () {
    const options = {
      theme: {
        palette: 'palette10'
      },
      chart: {
        type: 'pie',
        height: 200
      },
      series: this.seriesValue,
      labels: this.labelsValue
    }

    this.chart = new ApexCharts(this.element, options)

    this.chart.render()
  }

  disconnect () {
    this.chart.destroy()
  }
}
