import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="add_form"
export default class extends Controller {
  static targets = ['addButton', 'removeButton', 'hiddenForm']
  static values = {
    hiddenFormPresent: Boolean
  }

  connect () {
    if (this.hiddenFormPresentValue === true) {
      this.addButtonTarget.style.display = 'none'
    }
  }

  add (event) {
    event.preventDefault()

    this.hiddenFormTarget.style.display = 'grid'
    this.addButtonTarget.style.display = 'none'
  }

  remove (event) {
    event.preventDefault()

    const preferredInputs = this.hiddenFormTarget.querySelectorAll('input')
    preferredInputs.forEach(function (input) {
      input.value = ''
    })

    this.hiddenFormTarget.style.display = 'none'
    this.addButtonTarget.style.display = 'initial'
  }
}
