import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

// Connects to data-controller="demo-radial-bar-chart"
export default class extends Controller {
  connect () {
    const options = {
      theme: {
        palette: 'palette10'
      },
      chart: {
        height: 350,
        type: 'radialBar'
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          gradientToColors: ['#87D4F9'],
          stops: [0, 100]
        }
      },
      series: [70],
      labels: ['Progress']
    }
    this.chart = new ApexCharts(this.element, options)

    this.chart.render()
  }

  disconnect () {
    this.chart.destroy()
  }
}
