import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="attendances"
export default class extends Controller {
  static targets = ['attendanceCode']
  static values = { index: String, trait: String, type: String }

  initialize () {
    const codes = document.querySelectorAll('[id$=trait_id]')

    codes.forEach(code => {
      const presentButton = code.parentElement.querySelector('[id$=present]')
      const absentButton = code.parentElement.querySelector('[id$=absent]')
      const lateButton = code.parentElement.querySelector('[id$=late]')
      const note = code.parentElement.querySelector('[id$=notes]')
      const minutesLate = code.parentElement.querySelector('[id$=minutes_late]')

      const statusDiv = code.parentElement.previousElementSibling

      if (presentButton.checked === true) {
        this.updateStatusDiv(statusDiv, 'present')
        this.updateNotes(note, note.parentElement, 'present')
        this.updateMinutesLate(minutesLate, minutesLate.parentElement, 'present')
      } else if (absentButton.checked === true) {
        this.updateStatusDiv(statusDiv, 'absent')
        this.updateNotes(note, note.parentElement, 'absent')
        this.updateMinutesLate(minutesLate, minutesLate.parentElement, 'absent')
      } else if (lateButton.checked === true) {
        this.updateStatusDiv(statusDiv, 'late')
        this.updateNotes(note, note.parentElement, 'late')
        this.updateMinutesLate(minutesLate, minutesLate.parentElement, 'late')
      }
    })

    // remove this bit when we have CanBeHistorical for attendances
    this.changeAttendanceCount()
  }

  markAll () {
    const codes = document.querySelectorAll('[id$=trait_id]')
    const buttons = document.querySelectorAll('[id$=' + this.typeValue + ']')
    const notes = document.querySelectorAll('[id$=_notes]')
    const minutesLates = document.querySelectorAll('[id$=_minutes_late]')

    codes.forEach(code => {
      code.value = this.traitValue
    })

    buttons.forEach(button => {
      button.checked = true
    })

    notes.forEach(note => {
      this.updateNotes(note, note.parentElement, this.typeValue)
    })

    minutesLates.forEach(minutesLate => {
      this.updateMinutesLate(minutesLate, minutesLate.parentElement, this.typeValue)
      this.updateStatusDiv(minutesLate.parentElement.parentElement.previousElementSibling, this.typeValue)
    })
    this.changeAttendanceCount()
  }

  markAttendance () {
    const code = document.querySelector('[id$=attendance_data_attendance_forms_attributes_' + this.indexValue + '_trait_id]')
    code.value = this.traitValue
    this.showFields()
    this.changeAttendanceCount()
  }

  showFields () {
    const note = document.getElementById('attendance_data_attendance_forms_attributes_' + this.indexValue + '_notes')
    const noteDiv = note.parentElement

    const minutesLate = document.getElementById('attendance_data_attendance_forms_attributes_' + this.indexValue + '_minutes_late')
    const minutesLateDiv = minutesLate.parentElement

    const statusDiv = document.getElementById('attendance_data_attendance_forms_attributes_' + this.indexValue + '_trait_id').parentElement.previousElementSibling

    this.updateNotes(note, noteDiv, this.typeValue)
    this.updateMinutesLate(minutesLate, minutesLateDiv, this.typeValue)
    this.updateStatusDiv(statusDiv, this.typeValue)
  }

  changeAttendanceCount () {
    const presents = document.querySelectorAll('[id$=_trait_id_present]')
    const absents = document.querySelectorAll('[id$=_trait_id_absent]')
    const lates = document.querySelectorAll('[id$=_trait_id_late]')
    const totalCount = document.querySelectorAll('[id$=_trait_id]').length
    let presentCount = 0
    let absentCount = 0
    let lateCount = 0

    presents.forEach(present => {
      if (present.checked) {
        presentCount = presentCount + 1
      }
    })

    absents.forEach(absent => {
      if (absent.checked) {
        absentCount = absentCount + 1
      }
    })

    lates.forEach(late => {
      if (late.checked) {
        lateCount = lateCount + 1
      }
    })

    const unregisteredCount = totalCount - (presentCount + absentCount + lateCount)
    document.getElementById('present-count').textContent = presentCount
    document.getElementById('absent-count').textContent = absentCount
    document.getElementById('late-count').textContent = lateCount
    document.getElementById('unregistered-count').textContent = unregisteredCount
  }

  updateStatusDiv (statusDiv, type) {
    switch (type) {
      case 'present':
        statusDiv.style.backgroundColor = 'var(--clr-success-base)'
        break
      case 'absent':
        statusDiv.style.backgroundColor = 'var(--clr-danger-base)'
        break
      case 'late':
        statusDiv.style.backgroundColor = 'var(--clr-orange-500)'
        break
      default:
        break
    }
  }

  updateNotes (note, noteDiv, type) {
    switch (type) {
      case 'present':
        noteDiv.style.visibility = 'hidden'
        note.disabled = true
        break
      case 'absent':
        noteDiv.style.visibility = 'visible'
        note.disabled = false
        break
      case 'late':
        noteDiv.style.visibility = 'visible'
        note.disabled = false
        break
      default:
        break
    }
  }

  updateMinutesLate (minutesLate, minutesLateDiv, type) {
    switch (type) {
      case 'present':
        minutesLateDiv.style.visibility = 'hidden'
        minutesLate.disabled = true
        break
      case 'absent':
        minutesLateDiv.style.visibility = 'hidden'
        minutesLate.disabled = true
        break
      case 'late':
        minutesLateDiv.style.visibility = 'visible'
        minutesLate.disabled = false
        break
      default:
        break
    }
  }

  reloadWithSession () {
    // Get the session value
    const session = document.querySelector('[id$=attendance_session]').value

    // Get the url
    const url = new URL(window.location.href)

    // Append the session value into the URL
    url.searchParams.set('session', session)

    // Refresh the page
    window.location.replace(url.href)
  }
}
