import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="form-watch"
export default class extends Controller {
  connect () {
    const formTarget = document.getElementById(this.element.getAttribute('form'))
    if (!formTarget) { return }
    this.element.disabled = true
    formTarget.addEventListener('change', this.enable.bind(this))
    formTarget.addEventListener('input', this.enable.bind(this))
  }

  enable () {
    this.element.disabled = false
  }
}
