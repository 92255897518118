import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dropdown"
export default class extends Controller {
  static values = {
    form: String
  }

  get formElement () {
    const formAttribute = this.formValue
    return document.forms.namedItem(formAttribute)
  }

  caughtEvents = []

  connect () {
    this.element.addEventListener('toggle', this.toggle.bind(this))
    this.element.addEventListener('change', this.handleChange.bind(this))
    this.element.addEventListener('input', this.handleChange.bind(this))
  }

  disconnect () {
    this.element.removeEventListener('toggle', this.toggle.bind(this))
    this.element.removeEventListener('change', this.handleChange.bind(this))
    this.element.removeEventListener('input', this.handleChange.bind(this))
    super.disconnect()
  }

  close (event) {
    const { target } = event
    if (target.hidden || this.element.contains(target)) {
      return
    }

    this.element.removeAttribute('open')
  }

  toggle () {
    // Do nothing if when the dropdown is opened
    if (this.element.open) {
      return
    }

    // Create and dispatch new events based on the captured events
    // Dispatch the new event on the form element if there is one
    // Otherwise dispatch the event on the dropdown element
    this.caughtEvents.forEach((caughtEvent) => {
      const newEvent = new caughtEvent.constructor(caughtEvent.type, caughtEvent)
      if (this.formElement) {
        this.formElement.dispatchEvent(newEvent)
      } else {
        this.element.dispatchEvent(newEvent)
      }
    })
  }

  handleChange (event) {
    // Catch all the events that happen when the dropdown is open
    if (this.element.open) {
      event.stopPropagation()
      this.caughtEvents.push(event)
    }
  }
}
