import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hours', 'startTime', 'endTime']

  initialize () {
    this.update = this.update.bind(this)
  }

  connect () {
    this.update()
    this.startTimeTarget.addEventListener('input', this.update)
    this.endTimeTarget.addEventListener('input', this.update)
  }

  disconnect () {
    this.startTimeTarget.removeEventListener('input', this.update)
    this.endTimeTarget.removeEventListener('input', this.update)
  }

  update () {
    const timeStart = new Date('2007-01-01 ' + this.startTimeTarget.value)
    const timeEnd = new Date('2007-01-01 ' + this.endTimeTarget.value)
    const difference = timeEnd.getTime() - timeStart.getTime()
    const hours = Math.floor(difference / 3600000 % 24)
    const minutes = Math.round(difference / 60000 % 60)
    let hoursText = ''
    let minutesText = ''

    if (hours > 0) { hoursText = ((hours > 1) ? (hours + ' hours') : '1 hour') }

    if (minutes > 0 && minutes < 60) { minutesText = ((minutes > 1) ? (minutes + ' minutes') : '1 minute') }

    if (hours > 0 || minutes > 0) {
      this.hoursTarget.innerHTML = hoursText + ' ' + minutesText
    } else {
      this.hoursTarget.innerHTML = '0 hours'
    }
  }
}
