class Rectangle {
  canvas = document.getElementById('animationCanvas')
  ctx = this.canvas.getContext('2d')

  colorsAndTexts = [
    { color: '#FF4D4D', text: 'Sig Below' },
    { color: '#FFA64D', text: 'Below' },
    { color: '#4EC77B', text: 'At' },
    { color: '#33ADFF', text: 'Above' },
    { color: '#9966FF', text: 'Sec At' }
  ]

  constructor () {
    this.x = Math.random() * this.canvas.width
    this.y = Math.random() * this.canvas.height
    this.scale = 0
    const randomIndex = Math.floor(Math.random() * this.colorsAndTexts.length)
    this.color = this.colorsAndTexts[randomIndex].color
    this.text = this.colorsAndTexts[randomIndex].text
    this.speed = Math.random() * 0.005 + 0.001
    this.opacity = 1
    this.canvas.width = window.innerWidth
    this.canvas.height = window.innerHeight
  }

  draw () {
    this.ctx.save()
    this.ctx.globalAlpha = this.opacity
    this.ctx.fillStyle = this.color
    this.ctx.translate(this.x, this.y)
    this.ctx.scale(this.scale, this.scale)
    this.ctx.font = '18px Inter'
    const textWidth = this.ctx.measureText(this.text).width
    const padding = 20
    const rectWidth = textWidth + padding
    const rectHeight = 31
    const borderRadius = rectHeight / 2

    this.ctx.beginPath()
    this.ctx.roundRect(0, 0, rectWidth, rectHeight, borderRadius)
    this.ctx.fill()

    this.ctx.fillStyle = 'white'
    this.ctx.textAlign = 'center'
    this.ctx.textBaseline = 'middle'
    this.ctx.fillText(this.text, rectWidth / 2, rectHeight / 2)
    this.ctx.restore()
    this.ctx.globalAlpha = 1
  }

  update () {
    this.scale += this.speed

    if (this.scale >= 1) {
      this.opacity -= 0.01
    }
    if (this.opacity <= 0) {
      this.x = Math.random() * this.canvas.width
      this.y = Math.random() * this.canvas.height
      this.scale = 0
      const randomIndex = Math.floor(Math.random() * this.colorsAndTexts.length)
      this.color = this.colorsAndTexts[randomIndex].color
      this.text = this.colorsAndTexts[randomIndex].text
      this.speed = Math.random() * 0.005 + 0.001
      this.opacity = 1
    }
    this.draw()
  }
}

export default Rectangle
