import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

// Connects to data-controller="demo-horizontal-bar-chart"
export default class extends Controller {
  static values = {
    labels: Array
  }

  connect () {
    const options = {
      theme: {
        monochrome: {
          enabled: true,
          color: '#6732C0',
          shadeTo: 'light',
          shadeIntensity: 0.65
        }
      },
      series: [{
        data: [43, 21]
      }, {
        data: [44, 32]
      }],
      chart: {
        type: 'bar',
        height: 200
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: ['2019-2020', '2020-2021']
      },
      labels: this.labelsValue
    }
    this.chart = new ApexCharts(this.element, options)

    this.chart.render()
  }

  disconnect () {
    this.chart.destroy()
  }
}
