import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="segmented-control"
export default class extends Controller {
  static targets = ['control', 'segment']

  connect () {
    this.controlTargets.forEach((control) => {
      control.addEventListener('click', (event) => {
        this.resetRadioButtons()
        control.checked = true
        this.showOneSegment(control.value)
      })
    })

    // Default to the first segment
    this.controlTargets[0].click()
  }

  resetRadioButtons () {
    this.controlTargets.forEach((control) => { control.checked = false })
  }

  showOneSegment (segmentId) {
    this.segmentTargets.forEach((segment) => {
      if (segment.id === segmentId) {
        segment.style.display = 'initial'
      } else {
        segment.style.display = 'none'
      }
    })
  }
}
