import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="modal-picker"
export default class extends Controller {
  static targets = ['wrapper', 'content', 'launcher']
  static outlets = ['dropdown']

  closeModalOnClickOutside (event) {
    if (event.target === this.element) {
      event.stopPropagation()
      this.hide()
    }
  }

  connect () {
    if (this.element.id === 'modal') {
      this.show()
    }
  }

  show () {
    this.wrapperTarget.hidden = false
    this.wrapperTarget.setAttribute('open', 'true')
    this.wrapperTarget.setAttribute('aria-hidden', 'false')
    setTimeout(() => {
      this.wrapperTarget.classList.add('modal-animate')
    }, 250)
  }

  showFromOutsideElement (event) {
    event.preventDefault()
    const path = this.launcherTarget.dataset.path
    if (path) {
      document.getElementById(
        'remove-modal-button'
      ).href = path
    }
    document.getElementById(
      this.launcherTarget.dataset.reference
    ).hidden = false
    document.getElementById(
      this.launcherTarget.dataset.reference
    ).setAttribute('aria-hidden', 'false')
    document.getElementById(
      this.launcherTarget.dataset.reference
    ).setAttribute('open', 'true')
    setTimeout(() => {
      document
        .getElementById(this.launcherTarget.dataset.reference)
        .classList.add('modal-animate')
    }, 250)
    if (!this.hasDropdownOutlet) return
    this.dropdownOutlets.forEach((dropdown) =>
      dropdown.element.removeAttribute('open')
    )
  }

  hide () {
    this.wrapperTarget.classList.remove('modal-animate')

    setTimeout(() => {
      this.wrapperTarget.hidden = true
      this.wrapperTarget.setAttribute('aria-hidden', 'true')
      this.wrapperTarget.removeAttribute('open')
    }, 250)
  }

  dismissFromBackgroundClick (event) {
    if (event.target === this.contentTarget) {
      this.element.dispatchEvent(new Event('dismiss', { bubbles: true }))
    }
  }
}
