import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="table"
export default class extends Controller {
  static targets = ['sortTrigger', 'linkedCell']

  connect () {
    this.sortTriggerTargets.forEach(sortControl => {
      sortControl.addEventListener(
        'click',
        function (evt) {
          evt.preventDefault()
          this.sortByColumn(sortControl)
        }.bind(this)
      )
    })

    this.linkedCellTargets.forEach(linkedCell => {
      linkedCell.addEventListener(
        'click',
        function () { window.location = linkedCell.dataset.href }
      )
    })
  }

  disconnect () {
  }

  sortByColumn (button) {
    const columnSortOrder = this.nextSortOrderForColumn(button)
    this.resetSortOrders()
    button.dataset.sortOrder = columnSortOrder

    const columnIndex = button.dataset.columnIndex

    let switching = true
    let shouldSwitch = false
    const rows = this.element.rows

    const firstRowIndex = this.firstRowIndexForTable(this.element)

    const maxIterations = rows.length * 10
    let iterationCount = 0

    while (switching && (iterationCount < maxIterations)) {
      iterationCount += 1
      switching = false
      shouldSwitch = false

      let i = firstRowIndex
      for (i = firstRowIndex; i < (rows.length - 1); i++) {
        shouldSwitch = false
        const x = rows[i].getElementsByTagName('TD')[columnIndex]
        const y = rows[i + 1].getElementsByTagName('TD')[columnIndex]

        if (this.shouldSwapRows(x.dataset.sortOrder, y.dataset.sortOrder, columnSortOrder)) {
          shouldSwitch = true
          break
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i])
        switching = true
      }
    }
  }

  nextSortOrderForColumn (button) {
    const currentSortOrder = button.dataset.sortOrder
    if (currentSortOrder === 'asc') {
      return 'desc'
    } else {
      return 'asc'
    }
  }

  resetSortOrders () {
    this.sortTriggerTargets.forEach(sortControl => {
      sortControl.dataset.sortOrder = null
    })
  }

  firstRowIndexForTable (table) {
    const header = table.getElementsByTagName('thead')
    if (header.length === 0) { return 0 }

    const headerRows = header[0].getElementsByTagName('tr')
    return headerRows.length
  }

  shouldSwapRows (rowA, rowB, order) {
    const parsedValueA = this.isNumeric(rowA) ? parseFloat(rowA) : rowA
    const parsedValueB = this.isNumeric(rowB) ? parseFloat(rowB) : rowB

    const comparisonValueA = order === 'desc' ? parsedValueA : parsedValueB
    const comparisonValueB = order === 'desc' ? parsedValueB : parsedValueA

    return comparisonValueA < comparisonValueB
  }

  isNumeric (value) {
    const stringValue = `${value}`
    return !isNaN(stringValue) && !isNaN(parseFloat(stringValue))
  }
}
