import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="salary-grade-form"
export default class extends Controller {
  static targets = [
    'form',
    'scalePointSelect',
    'gradeScalesSubform',
    'subgradesSubform',
    'subgradesHeaders',
    'hasSubgrades',
    'gradeScalesSubform',
    'gradeScalesHeaders',
    'minMaxGradeScalesSubform',
    'singleGradeSubgradesSubform',
    'minMaxGradeSubgradesSubform'
  ]

  initialize () {
    this.showGradeScaleOrSubgradeSubform()

    this.formTarget.addEventListener('submit', (evt) => {
      evt.preventDefault()
      this.removeGradeScaleOrSubgradeSubformBeforeSubmit()

      evt.currentTarget.submit()
    }, true)
  }

  gradePointChange () {
    if (this.scalePointSelectTarget.value === 'single_value') {
      const maxValueInputs = document.getElementsByClassName('min-max-specific')
      this.removeErrors(maxValueInputs)
      this.clearValue(maxValueInputs)
    } else {
      const minValueInputs = document.getElementsByClassName('min-value')
      this.clearValue((minValueInputs))
    }
    this.showGradeScaleOrSubgradeSubform()
  }

  hasSubgradesChange ({ target }) {
    this.showGradeScaleOrSubgradeSubform(target)
  }

  showGradeScaleOrSubgradeSubform (target = this.hasSubgradesTarget) {
    if (target.checked) {
      this.gradeScalesSubformTarget.classList.add('hidden')

      if (this.scalePointSelectTarget.value === 'single_value') {
        this.subgradesSubformTarget.classList.add('single-subgrades-subform-wrapper')
        this.subgradesHeadersTarget.classList.add('single-value')
        this.subgradesSubformTarget.classList.remove('min-max-subgrades-subform-wrapper')
      } else {
        this.subgradesSubformTarget.classList.add('min-max-subgrades-subform-wrapper')
        this.subgradesSubformTarget.classList.remove('single-subgrades-subform-wrapper')
        this.subgradesHeadersTarget.classList.remove('single-value')
      }

      this.subgradesSubformTarget.classList.remove('hidden')
    } else {
      this.subgradesSubformTarget.classList.add('hidden')

      if (this.scalePointSelectTarget.value === 'single_value') {
        this.gradeScalesSubformTarget.classList.add('single-scale-grades-subform-wrapper')
        this.gradeScalesHeadersTarget.classList.add('single-value')
        this.gradeScalesSubformTarget.classList.remove('min-max-scale-grades-subform-wrapper')
      } else {
        this.gradeScalesSubformTarget.classList.add('min-max-scale-grades-subform-wrapper')
        this.gradeScalesSubformTarget.classList.remove('single-scale-grades-subform-wrapper')
        this.gradeScalesHeadersTarget.classList.remove('single-value')
      }

      this.gradeScalesSubformTarget.classList.remove('hidden')
    }
  }

  removeGradeScaleOrSubgradeSubformBeforeSubmit () {
    if (this.hasSubgradesTarget.checked) {
      const destroyGradeScaleInputs = this.gradeScalesSubformTarget.getElementsByClassName('remove-grade-scale-input')
      this.destroyElement(destroyGradeScaleInputs)

      if (this.scalePointSelectTarget.value === 'single_value') {
        const maxValueInputs = this.subgradesSubformTarget.getElementsByClassName('min-max-specific')
        this.clearValue(maxValueInputs)
      }
    } else {
      const removeSubgradesInputs = this.subgradesSubformTarget.getElementsByClassName('remove-subgrade-input')
      this.destroyElement(removeSubgradesInputs)

      if (this.scalePointSelectTarget.value === 'single_value') {
        const maxValueInputs = this.gradeScalesSubformTarget.getElementsByClassName('min-max-specific')
        this.clearValue(maxValueInputs)
      }
    }
  }

  destroyElement (inputs) {
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].value = true
    }
  }

  clearValue (inputs) {
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].value = null
    }
  }

  removeErrors (inputs) {
    Array.from(inputs).forEach((input) => {
      const inputErrorMessageEl = input.nextElementSibling
      if (inputErrorMessageEl?.className === 'form-validation__invalid-feedback') {
        const errorMessage = inputErrorMessageEl.innerText
        const errors = Array.from(document.getElementsByClassName('alert-banner__form-errors')[0].children)
        // Removing from the error summary at the top of the page
        errors.find((err) => err.innerText === errorMessage)?.remove()
        // Removing the error from the input field
        inputErrorMessageEl.remove()
      }
    })
  }
}
