import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']

  submitAndClose () {
    if (this.buttonTarget.form) {
      const newSubmitEvent = new Event('submit', { bubbles: true, cancelable: true })
      this.buttonTarget.form.dispatchEvent(newSubmitEvent)
    }

    this.element.remove()
  }
}
