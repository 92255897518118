import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dropdown-field"
export default class extends Controller {
  static targets = ['valuesWrapper', 'selectSpan']
  static values = { prompt: String }

  connect () {
    this.element.addEventListener('change', this.handleChange.bind(this))
  }

  handleChange (event) {
    const checked = this.valuesWrapperTarget.querySelectorAll('input:checked')

    for (let i = 0; i < checked.length; i++) {
      const label = this.valuesWrapperTarget.querySelector('label[for=' + checked[i].id + ']')

      if (label.dataset.all === 'true') {
        this.selectSpanTarget.innerText = label.textContent
        break
      }

      if (i === 0) {
        this.selectSpanTarget.innerText = label.textContent
      } else {
        this.selectSpanTarget.innerText = this.selectSpanTarget.innerText.concat(', ', label.textContent)
      }
    }

    if (checked.length === 0) {
      this.selectSpanTarget.innerText = this.promptValue
    }
  }
}
