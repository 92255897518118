import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'

// Connects to data-controller="popover"
export default class extends Controller {
  static targets = ['container', 'template', 'button']
  static values = {
    position: String
  }

  popover = undefined

  get #popoverElement () {
    return this.hasContainerTarget ? this.containerTarget : this.element
  }

  get #popoverPosition () {
    return this.hasPositionValue ? this.positionValue : 'top'
  }

  connect () {
    const target = this.#popoverElement
    const template = this.templateTarget

    const pos = this.#popoverPosition

    this.popover = createPopper(target, template, {
      placement: pos,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'right']
          }
        }
      ]
    })

    this.#connectEventListeners()
  }

  show () {
    this.templateTarget.setAttribute('data-show', '')
    this.popover.update()
  }

  hide () {
    this.templateTarget.removeAttribute('data-show')
  }

  #connectEventListeners () {
    this.buttonTarget.addEventListener('click', (event) => {
      this.show()
    })
    this.buttonTarget.addEventListener('keydown', (event) => {
      event.code === 'Space' ? this.show() : this.hide()
    })

    window.addEventListener('click', (event) => {
      if (event.target !== this.buttonTarget) {
        this.hide()
      }
    })

    window.addEventListener('keydown', (event) => {
      if (event.code !== 'Space') {
        this.hide()
      }
    })
  }
}
