import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ['search', 'expandedState']
  static values = {
    collapsible: {
      type: Boolean,
      default: false
    }
  }

  initialize () {
    this.submitForm = debounce(this.submitForm, 1000).bind(this)
    if (this.collapsibleValue && JSON.parse(this.searchTarget.getAttribute('aria-expanded'))) {
      const length = this.searchTarget.value.length
      this.searchTarget.setSelectionRange(length, length)
    }
  }

  submitForm () {
    this.element.requestSubmit()
  }

  toggleSearch () {
    if (!this.collapsibleValue) return

    if (this.searchTarget.classList.length) { this.searchTarget.classList.remove('collapsed', 'expanded') }

    if (!JSON.parse(this.searchTarget.getAttribute('aria-expanded'))) {
      this.searchTarget.setAttribute('aria-expanded', true)
      this.searchTarget.focus()
      this.setExpandedState(true)
    } else {
      this.searchTarget.setAttribute('aria-expanded', false)
      this.setExpandedState(false)
    }
  }

  setExpandedState (state) {
    if (!this.hasExpandedStateTarget) return
    this.expandedStateTarget.value = state
  }

  clearForm (event) {
    event.preventDefault()
    this.searchTarget.value = ''
    this.setExpandedState(false)
    this.element.requestSubmit()
  }
}
