import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'
import { DEBOUNCETIME } from '../utils/constants'

// Connects to data-controller="auto-save"
export default class extends Controller {
  initialize () {
    this.submitForm = debounce(this.submitForm, DEBOUNCETIME).bind(this)
    this.hideSpinner = debounce(this.hideSpinner, DEBOUNCETIME).bind(this)
    this.spinner = document.querySelector('.filters-spinner')
  }

  connect () {
    this.element.addEventListener('input', this.submitForm.bind(this))
    this.element.addEventListener('change', this.submitForm.bind(this))
  }

  async submitForm (event) {
    event.stopImmediatePropagation()
    event.preventDefault()

    const confirmMessage = this.element.dataset.confirm
    if (confirmMessage && !window.confirm(confirmMessage)) {
      return false
    }

    document.documentElement.addEventListener('turbo:submit-end', () => {
      this.hideSpinner()
    })

    if (this.spinner) {
      this.spinner.removeAttribute('hidden')
    }

    if (this.element.id === 'complex-filters') {
      this.saveFilters()
    } else {
      this.element.requestSubmit()
    }
  }

  async hideSpinner () {
    if (this.spinner) {
      this.spinner.setAttribute('hidden', '')
    }
  }

  async saveFilters () {
    const formData = new FormData(this.element)

    try {
      const response = await fetch('/filters', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ path: window.location.pathname, fields: new URLSearchParams(formData).toString() })
      })

      const data = await response.json()

      // Don't submit the form, but pass the filter ID to the original URL
      window.location.href = window.location.origin + window.location.pathname + '?_filter=' + data.id
    } catch (error) {
      // On failure, we try submit anyway - it's better than nothing
      this.element.requestSubmit()
    }
  }
}
