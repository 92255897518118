import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="searchable-item-picker"
export default class extends Controller {
  static targets = [
    'checkbox', 'selectedItemsCount', 'selectedItemsButton',
    'searchableItem', 'searchInput',
    'filter', 'selectAllRadio',
    'shownItemsCount']

  get cssClasses () {
    const block = 'searchable-item-picker'
    return {
      checkedCheckbox: `${block}__list-item-checkbox--checked`,
      checkbox: `${block}__list-item-checkbox`,
      listItem: `${block}__list-item`,
      checkboxInput: `${block}__list-item-checkbox-input`,
      hiddenListItem: `${block}__list-item--hidden`,
      selectedItemsOnly: `${block}__selected-items--only`,
      hiddenFilter: `${block}__filter--hidden`,
      selectAllEnabled: `${block}__select-all-radio--enabled`
    }
  }

  connect () {
    this.selectedItemsButtonTarget.addEventListener('click', this.toggleSelectedItems.bind(this))
    this.refreshContent()
    this.initializeSearch()
    this.initializeFilters()
    this.initializeSelectAll()
  }

  refreshContent () {
    this.shownItemsCount = this.searchableItemTargets.length
    this.selectedItemsCount = 0
    this.selectedItemsOnly = false

    this.refreshCheckboxes()
    this.updateShownItemsCount()
    this.updateSelectedItemsCount()
    this.updateSelectAllRadio()
  }

  submit (event) {
    event.preventDefault()
    event.target.form.submit()
  }

  loadItems (event) {
    event.preventDefault()
    this.element.dispatchEvent(new Event('loadItems', { bubbles: true }))
  }

  /*
   * Items count section
   */
  updateShownItemsCount () {
    this.shownItemsCountTarget.innerText = this.shownItemsCount
  }

  /*
   * Selected items section
   */
  updateSelectedItemsCount () {
    this.selectedItemsCountTarget.innerText = this.selectedItemsCount
  }

  toggleSelectedItems (event) {
    if (event) {
      event.preventDefault()
    }

    if (this.selectedItemsOnly) {
      this.selectedItemsOnly = false
      this.selectedItemsButtonTarget.classList.remove(this.cssClasses.selectedItemsOnly)

      this.searchableItemTargets.forEach(this.showSearchableItem.bind(this))
    } else {
      this.selectedItemsOnly = true
      this.selectedItemsButtonTarget.classList.add(this.cssClasses.selectedItemsOnly)

      this.searchableItemTargets.forEach((searchableItemEl) => {
        const checkboxInputEl = searchableItemEl.getElementsByClassName(this.cssClasses.checkboxInput)[0]

        if (checkboxInputEl.checked) {
          this.showSearchableItem(searchableItemEl)
        } else {
          this.hideSearchableItem(searchableItemEl)
        }
      })
    }
    this.updateSelectAllRadio()
  }

  /*
   * Search section
   */
  initializeSearch () {
    this.searchInputTarget.addEventListener('input', this.updateSearch.bind(this))
    this.searchInputTarget.addEventListener('change', this.updateSearch.bind(this))
  }

  updateSearch (event) {
    if (event) {
      event.stopImmediatePropagation()
    }

    this.searchableItemTargets.forEach((searchableItemEl) => {
      // Making both strings upper-case makes the search case-insensitive
      if (searchableItemEl.innerText.toUpperCase().includes(this.searchInputTarget.value.toUpperCase())) {
        this.showSearchableItem(searchableItemEl)
      } else {
        this.hideSearchableItem(searchableItemEl)
      }
    })
  }

  closeModal (event) {
    if (event) {
      event.stopImmediatePropagation()
    }

    this.element.dispatchEvent(new Event('dismiss', { bubbles: true }))
  }

  /*
   * Filters section
   */
  initializeFilters () {
    this.filterTargets.forEach((filterEl) => {
      if (Number(filterEl.dataset.searchableItemPickerFilterLevel) > 0) {
        filterEl.classList.add(this.cssClasses.hiddenFilter)
      }

      filterEl.addEventListener('change', this.showFilteredItems.bind(this))
      filterEl.addEventListener('input', this.showFilteredItems.bind(this))
    })
  }

  showFilteredItems (event) {
    if (event) {
      event.stopImmediatePropagation()
    }
    const selectedFilterIds = this.selectedFilterIds(event)

    this.searchableItemTargets.forEach((searchableItemEl) => {
      const itemFilterIdsString = searchableItemEl.dataset.searchableItemFilterIds

      if (typeof itemFilterIdsString !== 'undefined' && itemFilterIdsString !== '') {
        const itemFilterIds = JSON.parse(itemFilterIdsString)

        const showItem = selectedFilterIds.every(i => itemFilterIds.includes(i))
        if (showItem) {
          this.showSearchableItem(searchableItemEl)
        } else {
          this.hideSearchableItem(searchableItemEl)
        }
      } else {
        this.hideSearchableItem(searchableItemEl)
      }
    })

    this.showNextFilter(event)
    this.updateSelectAllRadio()
  }

  showNextFilter (event) {
    if (event) {
      event.stopImmediatePropagation()
    }

    const currentFilterLevel = this.currentFilterLevel(event)
    const selectedFilter = event.target.selectedOptions[0].value

    this.filterTargets.filter((filterEl) => {
      return Number(filterEl.dataset.searchableItemPickerFilterLevel) > currentFilterLevel
    }).forEach((filterEl) => {
      if (filterEl.name === selectedFilter || filterEl === event.target) {
        filterEl.classList.remove(this.cssClasses.hiddenFilter)
      } else {
        filterEl.classList.add(this.cssClasses.hiddenFilter)
      }
    })
  }

  selectedFilterIds (event) {
    const selectedValues = this.selectedFilterElements(event).map((filter) => {
      const selectedFilterValues = Array.from(filter.selectedOptions).map(option => option.value)
      return selectedFilterValues
    })
    return selectedValues.flat().filter(Boolean)
  }

  selectedFilterElements (event) {
    const currentFilterLevel = this.currentFilterLevel(event)
    return this.filterTargets.filter((filterEl) => {
      const filterLevel = filterEl.dataset.searchableItemPickerFilterLevel
      const lessThanCurrentLevel = Number(filterLevel) <= currentFilterLevel
      const hidden = Array.from(filterEl.classList).includes(this.cssClasses.hiddenFilter)
      return lessThanCurrentLevel && !hidden
    })
  }

  currentFilterLevel (event) {
    return Number(event.target.dataset.searchableItemPickerFilterLevel)
  }

  /*
   * Select all section
   */
  initializeSelectAll () {
    this.selectAllEnabled = false
    this.selectAllRadioTarget.addEventListener('click', this.toggleSelectAllElements.bind(this))
  }

  toggleSelectAllElements (event) {
    event.stopImmediatePropagation()

    if (this.selectAllEnabled) {
      this.selectAllEnabled = false
      this.selectAllRadioTarget.classList.remove(this.cssClasses.selectAllEnabled)
      this.checkboxTargets.filter(this.elementIsVisible).forEach(this.uncheck.bind(this))
      this.selectedItemsCount = 0
    } else {
      this.selectAllEnabled = true
      this.selectAllRadioTarget.classList.add(this.cssClasses.selectAllEnabled)
      const visibleCheckboxes = this.checkboxTargets.filter(this.elementIsVisible)
      visibleCheckboxes.forEach(this.check.bind(this))
      this.selectedItemsCount = visibleCheckboxes.length
    }
    this.updateSelectedItemsCount()
  }

  updateSelectAllRadio () {
    const visibleCheckboxes = this.checkboxTargets.filter(this.elementIsVisible)
    const allSelected = visibleCheckboxes.every((checkboxEl) => {
      return checkboxEl.getElementsByClassName(this.cssClasses.checkboxInput)[0].checked
    })

    if (allSelected) {
      this.selectAllEnabled = true
      this.selectAllRadioTarget.classList.add(this.cssClasses.selectAllEnabled)
    } else {
      this.selectAllEnabled = false
      this.selectAllRadioTarget.classList.remove(this.cssClasses.selectAllEnabled)
    }
  }

  /*
   * Item manipulation section
   */

  refreshCheckboxes () {
    this.checkboxTargets.forEach((checkboxEl) => {
      checkboxEl.classList.remove(this.cssClasses.checkedCheckbox)
      if (checkboxEl.getElementsByTagName('input')[0].checked) {
        this.check(checkboxEl)
      }
    })
  }

  toggleItem (event) {
    const listItemEl = event.target.closest(`.${this.cssClasses.listItem}`)
    const inputEl = listItemEl.getElementsByClassName(this.cssClasses.checkboxInput)[0]
    const checkboxEl = listItemEl.getElementsByClassName(this.cssClasses.checkbox)[0]

    if (inputEl.checked) {
      this.uncheck(checkboxEl)
    } else {
      this.check(checkboxEl)
    }
    this.updateSelectAllRadio()
  }

  check (checkboxEl) {
    const checkboxInputEl = checkboxEl.getElementsByClassName(this.cssClasses.checkboxInput)[0]

    checkboxInputEl.checked = true
    checkboxEl.classList.add(this.cssClasses.checkedCheckbox)

    this.selectedItemsCount += 1
    this.updateSelectedItemsCount()
  }

  uncheck (checkboxEl) {
    const checkboxInputEl = checkboxEl.getElementsByClassName(this.cssClasses.checkboxInput)[0]

    checkboxInputEl.checked = false
    checkboxEl.classList.remove(this.cssClasses.checkedCheckbox)

    this.selectedItemsCount -= 1
    this.updateSelectedItemsCount()
  }

  elementIsVisible (checkboxEl) {
    return checkboxEl.offsetParent !== null
  }

  showSearchableItem (searchableItemEl) {
    if (this.elementIsVisible(searchableItemEl)) {
      return
    }

    searchableItemEl.classList.remove(this.cssClasses.hiddenListItem)
    this.shownItemsCount += 1
    this.updateShownItemsCount()
  }

  hideSearchableItem (searchableItemEl) {
    if (!this.elementIsVisible(searchableItemEl)) {
      return
    }

    searchableItemEl.classList.add(this.cssClasses.hiddenListItem)
    this.shownItemsCount -= 1
    this.updateShownItemsCount()
  }
}
