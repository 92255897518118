import Trix from 'trix'

// Create the "underline" toolbar button
Trix.config.lang.underline = 'Underline'
Trix.config.textAttributes.underline = {
  style: { textDecoration: 'underline' },
  inheritable: true,
  parser: function (element) {
    const style = window.getComputedStyle(element)
    return style.textDecoration === 'underline'
  }
}
