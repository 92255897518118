import { Controller } from '@hotwired/stimulus'

// For non-dropdown inputs (radio, checkbox) that are outside of a form but need to trigger a change on the form
// For dropdowns, use the `dropdown_controller.js` instead
// Connects to data-controller="external-input"
export default class extends Controller {
  connect () {
    if (this.element.form) {
      this.element.addEventListener('input', this.handleChange.bind(this))
    }
  }

  disconnect () {
    this.element.removeEventListener('input', this.handleChange.bind(this))
    super.disconnect()
  }

  handleChange () {
    const newInputEvent = new Event('input', { bubbles: true, cancelable: true })
    this.element.form.dispatchEvent(newInputEvent)
  }
}
