import NestedForm from 'stimulus-rails-nested-form'

// Connects to data-controller="nested-form-add-one"
export default class extends NestedForm {
  static targets = ['addButton', 'removeButton']
  connect () {
    if (!this.hasRemoveButtonTarget) {
      this.addButtonTarget.style.display = 'initial'
    }
  }

  add (event) {
    this.addButtonTarget.style.display = 'none'
    super.add(event)
  }

  remove (event) {
    this.addButtonTarget.style.display = 'initial'
    super.remove(event)
  }
}
