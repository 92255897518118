import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js'

// Connects to data-controller="select"
export default class extends Controller {
  choices
  static values = {
    sort: {
      type: Boolean, default: true
    },
    defaultGroup: String,
    groupDivider: String
  }

  connect () {
    this.choices = new Choices(this.element, {
      allowHTML: true,
      removeItemButton: true,
      shouldSort: this.sortValue,
      placeholder: true,
      placeholderValue: this.element.getAttribute('placeholder'),
      callbackOnCreateTemplates: () => {
        return {
          choice: (...args) => {
            const modifiedData = Object.assign({}, args[1])
            modifiedData.label = this.labelForChoice(modifiedData.label)

            args[1] = modifiedData

            return Choices.defaults.templates.choice.call(this, ...args)
          },
          item: (...args) => {
            const modifiedData = Object.assign({}, args[1])
            modifiedData.label = this.labelForSelected(modifiedData.label)

            args[1] = modifiedData

            return Choices.defaults.templates.item.call(this, ...args)
          }
        }
      }
    })
  }

  disconnect () {

  }

  // Returns a label adjusted for a choice item (i.e the stuff in the dropdown).
  // If a groupDividerValue exists, we know that we already use optgroups, so we want
  // to remove the group prefix from the label.
  labelForChoice (originalLabel) {
    if (this.hasGroupDividerValue && originalLabel.includes(this.groupDividerValue)) {
      const item = originalLabel.split(this.groupDividerValue)[1].trim()
      return item
    }

    return originalLabel
  }

  // Returns a label adjusted for a selected item (i.e what appears in the select box
  // after the dropdown is closed). If a defaultGroupValue exists, we remove the
  // group prefix from the selected item when its group matches the default one.
  // This is especially helpful when displaying grades: if the selected grade is in the
  // expected band for the pupil, there is no need to show the year band.
  labelForSelected (originalLabel) {
    if (this.hasGroupDividerValue && originalLabel.includes(this.groupDividerValue)) {
      const [group, item] = originalLabel.split(this.groupDividerValue).map(s => s.trim())

      if (group === this.defaultGroupValue) {
        return item
      }
    }

    return originalLabel
  }
}
