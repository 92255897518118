/* global CanvasRenderingContext2D */
/* global requestAnimationFrame */
import { Controller } from '@hotwired/stimulus'
import Rectangle from '../utils/rectangle'

export default class extends Controller {
  static targets = ['animationCanvas', 'introModal', 'onboardModal', 'onboardNext', 'onboardPrev', 'onboardGrid', 'onboardMedia', 'onboardText', 'onboardDot']

  pos = { x: 0, y: 0 }

  connect () {
    this.initializeAnimation()
    this.resizeCanvas()
  }

  updateCursorPosition (e) {
    this.pos.x = (e.clientX / window.innerWidth).toFixed(2)
    this.pos.y = (e.clientY / window.innerHeight).toFixed(2)
    document.documentElement.style.setProperty('--x', this.pos.x)
    document.documentElement.style.setProperty('--y', this.pos.y)
  }

  initializeAnimation () {
    CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
      this.beginPath()
      this.moveTo(x + radius, y)
      this.lineTo(x + width - radius, y)
      this.arcTo(x + width, y, x + width, y + radius, radius)
      this.lineTo(x + width, y + height - radius)
      this.arcTo(x + width, y + height, x + width - radius, y + height, radius)
      this.lineTo(x + radius, y + height)
      this.arcTo(x, y + height, x, y + height - radius, radius)
      this.lineTo(x, y + radius)
      this.arcTo(x, y, x + radius, y, radius)
      this.closePath()
    }

    const rectangles = []
    const addRectangle = () => {
      if (rectangles.length < 10) {
        rectangles.push(new Rectangle())
      }
    }
    for (let i = 0; i < 10; i++) {
      addRectangle()
    }
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      document.querySelector('.login-bg-follower').style.display = 'none'
      this.animationCanvasTarget.style.display = 'none'
      document.querySelectorAll('.begin-btn, .button-light').forEach((el) => {
        el.style.transition = 'none'
      })
    } else {
      this.animate(rectangles)
    }
  }

  animate (rectangles) {
    const ctx = this.animationCanvasTarget.getContext('2d')
    const drawFrame = () => {
      ctx.clearRect(0, 0, this.animationCanvasTarget.width, this.animationCanvasTarget.height)

      for (const rectangle of rectangles) {
        rectangle.update()
      }
      this.addRectangle(rectangles)
      requestAnimationFrame(drawFrame)
    }
    drawFrame()
  }

  addRectangle (rectangles) {
    if (rectangles.length < 10) {
      rectangles.push(new Rectangle())
    }
  }

  resizeCanvas () {
    window.addEventListener('resize', () => {
      this.animationCanvasTarget.width = window.innerWidth
      this.animationCanvasTarget.height = window.innerHeight
    })
  }

  beginOnboarding () {
    this.introModalTarget.classList.add('hide')
    setTimeout(() => {
      this.onboardModalTarget.classList.add('show')
    }, 1000)
  }

  handleOnboardNextClick () {
    let onboardStep = Number(this.onboardGridTarget.getAttribute('data-modal-step'))
    if (this.onboardNextTarget.textContent === 'Get Started') {
      window.location.href = '/splash_pages/finish'
      return
    }
    onboardStep += 1
    this.onboardPrevTarget.disabled = false
    if (onboardStep >= this.onboardTextTargets.length - 1) {
      this.onboardNextTarget.textContent = 'Get Started'
    } else {
      this.onboardNextTarget.textContent = 'Next'
    }
    this.updateOnboardStep(onboardStep)
  }

  handleOnboardPrevClick () {
    let onboardStep = Number(this.onboardGridTarget.getAttribute('data-modal-step'))
    onboardStep -= 1
    this.onboardNextTarget.disabled = false
    if (onboardStep <= 0) {
      onboardStep = 0
      this.onboardPrevTarget.disabled = true
    } else {
      this.onboardNextTarget.textContent = 'Next'
      this.onboardNextTarget.onclick = null
    }
    this.updateOnboardStep(onboardStep)
  }

  updateOnboardStep (onboardStep) {
    this.onboardGridTarget.setAttribute('data-modal-step', onboardStep)
    this.updateOnboardElements(this.onboardDotTargets, onboardStep)
    this.updateOnboardElements(this.onboardTextTargets, onboardStep)
    this.updateOnboardElements(this.onboardMediaTargets, onboardStep)
  }

  updateOnboardElements (elements, step) {
    elements.forEach((item, index) => {
      if (index === step) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }
    })
  }
}
