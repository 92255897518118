import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="theme-picker"
export default class extends Controller {
  setColourValue (val) {
    document.querySelector('body').setAttribute('data-theme', val)
  }

  switchColourValue () {
    const colour = this.element.querySelector('input:checked').id
    switch (colour) {
      case 'blue':
      case 'green':
      case 'orange':
      case 'pink':
      case 'red':
      case 'teal':
      case 'yellow':
      case 'purple':
        this.setColourValue(colour)
        break
      default:
        this.setColourValue('default')
    }
  }
}
