import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="absences--calculate-time-lost"
export default class extends Controller {
  static targets = [
    'beganAt',
    'beganAtTime',
    'beganAtPeriodSelector',
    'finishedAt',
    'finishedAtTime',
    'finishedAtPeriodSelector',
    'contractSelect'
  ]

  static values = {
    staffMemberId: String,
    absenceModel: String
  }

  connect () {
    this.beganAtTarget.addEventListener('change', this.CalculateTimeLost.bind(this))
    this.beganAtTimeTarget.addEventListener('change', this.CalculateTimeLost.bind(this))
    this.finishedAtTarget.addEventListener('change', this.CalculateTimeLost.bind(this))
    this.finishedAtTimeTarget.addEventListener('change', this.CalculateTimeLost.bind(this))
    if (this.hasContractSelectTarget) {
      this.contractSelectTarget.addEventListener('change', this.CalculateTimeLost.bind(this))
    }

    this.beganAtPeriodSelectorTarget.querySelectorAll('input').forEach((input) => {
      input.addEventListener('change', this.CalculateTimeLost.bind(this))
    })

    this.finishedAtPeriodSelectorTarget.querySelectorAll('input').forEach((input) => {
      input.addEventListener('change', this.CalculateTimeLost.bind(this))
    })
  }

  private

  CalculateTimeLost (event) {
    const params = {
      began_at: this.beganAtTarget.value,
      began_at_time: this.beganAtTimeTarget.value,
      began_at_period: this.beganAtPeriodSelectorTarget.querySelector('input:checked')?.value,
      finished_at: this.finishedAtTarget.value,
      finished_at_time: this.finishedAtTimeTarget.value,
      finished_at_period: this.finishedAtPeriodSelectorTarget.querySelector('input:checked')?.value,
      absence_model: this.absenceModelValue
    }

    if (this.hasContractSelectTarget) {
      params.contract_ids = []
      const contractSelectOptions = this.contractSelectTarget.querySelectorAll('input:checked')

      contractSelectOptions.forEach((option) => {
        if (option.value !== 'undefined' && option.value !== '') {
          params.contract_ids.push(option.value)
        }
      })
    }

    this.calculate(params)
  }

  calculate (params) {
    if (params.began_at !== '' && params.finished_at !== '') {
      const url = '/staff_members/' + this.staffMemberIdValue + '/absences/time_lost_calculations'

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
        },
        body: JSON.stringify(params)
      }).then(response => response.text())
        .then(html => window.Turbo.renderStreamMessage(html))
    }
  }
}
