import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="contracts--further-details-form"
export default class extends Controller {
  static targets = ['workingPatternField', 'toggleableSection', 'contractWorkingPatternShow']

  connect () {
    if (
      this.workingPatternFieldTarget.value.toLowerCase().length > 0 &&
      this.workingPatternFieldTarget.value.toLowerCase() !== 'custom'
    ) {
      this.showWorkingPattern(this.workingPatternFieldTarget)
    }
  }

  changeWorkingPattern (event) {
    const customWorkingPatternId = event.target.dataset.customWorkingPatternId

    if (event.target.value === customWorkingPatternId) {
      document.location.reload()
    } else if (event.target.value.toLowerCase() === 'custom') {
      this.hideWorkingPattern()
      this.addNestedForm()
    } else if (event.target.value) {
      this.removeNestedForm()
      this.showWorkingPattern(event.target)
    }
  }

  private

  addNestedForm () {
    this.toggleableSectionTarget.querySelector('a[data-type="add"]').click()
  }

  removeNestedForm () {
    const nestedForms = this.toggleableSectionTarget.getElementsByClassName('nested-form-wrapper')

    if (nestedForms.length > 0) {
      Array.from(nestedForms).forEach(function (form) {
        const removeLink = form.querySelector('a.remove-form')
        const hiddenDestroyField = form.querySelector('input.nested-form-destroy')

        // We have a nested form within a nested form here if the working pattern is flexible.
        // The Custom working pattern hidden destroy will not have the correct value because of the deep nesting
        if (hiddenDestroyField !== null) {
          hiddenDestroyField.value = '1'
        }

        if (removeLink !== null) {
          removeLink.click()
        }
      })
    }
  }

  showWorkingPattern (target) {
    const id = target.value

    fetch(
    `/contracts/working_patterns/${id}`
    ).then(response => response.text())
      .then(html => window.Turbo.renderStreamMessage(html))
  }

  hideWorkingPattern () {
    if (this.contractWorkingPatternShowTarget.children.length > 0) {
      fetch(
        '/contracts/working_patterns/nil'
      ).then(response => response.text())
        .then(html => window.Turbo.renderStreamMessage(html))
    }
  }
}
