import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="stacked-bar"
export default class extends Controller {
  static targets = ['stack']

  connect () {
    function hideOverflowStackText (stackedBar) {
      stackedBar.stackTargets.forEach((stack) => {
        const stackWidth = stack.offsetWidth

        stack.querySelectorAll('.stacked-bar-segment-details').forEach((textDiv) => {
          if (textDiv.offsetWidth >= stackWidth) {
            textDiv.querySelectorAll('.table__result').forEach(text => { text.style.visibility = 'hidden' })
          } else {
            textDiv.querySelectorAll('.table__result').forEach(text => { text.style.visibility = 'visible' })
          }
        })
      })
    }

    const bar = this
    window.addEventListener('resize', function () {
      hideOverflowStackText(bar)
    })

    hideOverflowStackText(bar)
  }
}
