import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="students-selector"
export default class extends Controller {
  static get targets () {
    return ['observeeCheckbox', 'observationForm', 'studentCheckbox',
      'copyFromPreviousStudentIds', 'bulkFillStudentIds', 'selectAllStudents']
  }

  connect () {
    this.selectAll()
  }

  select () {
    const totalCheckboxes = this.studentCheckboxTargets.length
    const selectedCboxes = this.studentCheckboxTargets.filter(ch => ch.checked === true).map(e => e.value)

    this.addStudentIds(this.copyFromPreviousStudentIdsTargets, selectedCboxes)
    this.addStudentIds(this.bulkFillStudentIdsTargets, selectedCboxes)

    this.observeeCheckboxTargets.forEach(obsCheckbox => obsCheckbox.remove())
    this.observationFormTargets.forEach((obsForm) => {
      selectedCboxes.forEach(studentId => this.add_to_observation_form(obsForm, studentId))
    })

    const selectAllCheckbox = this.selectAllStudentsTarget
    if (totalCheckboxes === selectedCboxes.length) {
      selectAllCheckbox.checked = true
      selectAllCheckbox.indeterminate = false
    } else if (selectedCboxes.length > 0) {
      selectAllCheckbox.indeterminate = true
      selectAllCheckbox.checked = false
    } else {
      selectAllCheckbox.checked = false
      selectAllCheckbox.indeterminate = false
    }
  }

  selectAll () {
    const selectAllCheckbox = this.selectAllStudentsTarget
    const checkboxes = this.studentCheckboxTargets
    const copyFromPrevious = this.copyFromPreviousStudentIdsTargets
    const bulkFillStudent = this.bulkFillStudentIdsTargets
    const observationForms = this.observationFormTargets

    const checkboxValues = checkboxes.map(e => e.value)

    if (selectAllCheckbox.checked) {
      this.addStudentIds(copyFromPrevious, checkboxValues)
      this.addStudentIds(bulkFillStudent, checkboxValues)
      checkboxes.forEach((studentCheckbox) => {
        studentCheckbox.checked = true
      })

      this.observeeCheckboxTargets.forEach(obsCheckbox => obsCheckbox.remove())
      observationForms.forEach((obsForm) => {
        checkboxValues.forEach(studentId => this.add_to_observation_form(obsForm, studentId))
      })
    } else {
      this.addStudentIds(copyFromPrevious, [])
      this.addStudentIds(bulkFillStudent, [])
      checkboxes.forEach((studentCheckbox) => {
        studentCheckbox.checked = false
      })

      this.observeeCheckboxTargets.forEach(obsCheckbox => obsCheckbox.remove())
    }
  }

  addStudentIds (elements, selectedCboxes) {
    elements.forEach((element) => {
      document.getElementById(element.id).value = selectedCboxes
    })
  }

  add_to_observation_form (form, studentId) {
    const studentInput = document.createElement('INPUT')
    studentInput.setAttribute('value', studentId)
    studentInput.setAttribute('class', 'observee_ids')
    studentInput.setAttribute('type', 'hidden')
    studentInput.setAttribute('name', 'observee_ids[]')
    studentInput.setAttribute('data-students-selector-target', 'observeeCheckbox')
    form.prepend(studentInput)
  }
}
